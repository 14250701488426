import React from "react";
import Skeleton from "react-loading-skeleton";
import { useAllReservationContext } from "../../shared/context/AllReservationContext/AllReservationContext";

import { ReservationCardElement } from "./ReservationCardElement";
import { Icon } from "../../../shared/Icon/Icon";

export const AddWorkspaceCalendar = () => {
  const { setOpenWorkspacePicker } = useAllReservationContext();

  return (
    <div className="ReservationCard AddWorkspaceCalendar default-box">
      <div className="ReservationCardHeader">
        <div className="flex-a-center justify-content-between mb-3">
          <ReservationCardElement
            icon="rooms-icon"
            element={<Skeleton width={130} className="ml-2" />}
            className="ReservationListCard__icon"
          />

          <ReservationCardElement
            icon="user-rounded"
            element={<Skeleton width={20} className="ml-2" />}
            className="flex-a-center"
          />
        </div>

        <ReservationCardElement
          icon="tags-mobile"
          element={<Skeleton width={50} className="ml-2" />}
          className="mb-2"
        />

        <ReservationCardElement
          icon="equipment-mobile"
          element={<Skeleton width={60} count={3} className="ml-2" />}
        />
      </div>

      <div
        className="AddWorkspaceCalendar__add"
        onClick={() => setOpenWorkspacePicker(true)}
      >
        <Icon icon="plus-icon-bordered" />
        <h5 className="text-center">Add workspace calendar</h5>
      </div>
    </div>
  );
};
