import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { getReservationTypes } from "../helpers/getReservationActionsTypes";
import { useCheckUserPermissions } from "../../../../lib/hooks/useCheckUserPermissions";

import {
  Event as RoomEvent,
  EventCheckInStatus,
} from "../../../../api/grpc/booking/ggevent/ggevent";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import { Event as DeskEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { RESERVATIONS_MAP_ROOT_PATH } from "../../../../lib/routes/routes";
import {
  DropdownList,
  DropdownListOptions,
} from "../../../shared/DropdownList/DropdownList";
import { Icon } from "../../../shared/Icon/Icon";
import { CheckInDeskModal } from "../../Reserve/Modals/CheckInDeskModal";
import { CheckOutModal } from "../../Reserve/Modals/CheckOutModal";

import { DeleteModal } from "../../Reserve/Modals/DeleteModal";
import { UpdateReservationModal } from "../../Reserve/Modals/UpdateReservationModal";
import { CheckInRequired } from "../../Reserve/Modals/CheckInRequired";
import { RolePermissions } from "../../../../api/grpc/account/account";
import { MainResources } from "../../../../lib/types/main.types";

type ModalTypes =
  | "modifyReservation"
  | "deleteReservation"
  | "checkInDesk"
  | "checkOut";

export interface Props {
  type: MainResources;
  workplaceId: string;
  event: DeskEvent | RoomEvent;
  startTime: string;
  endTime: string;
  name: string;
  locationPath: DeskLocationItem[];
  checkInStatus: EventCheckInStatus | null;
  allowDeleteOption: boolean;
  isAttendee?: boolean;
  qrCodeRequired?: boolean;
  refetchDesk?: () => void;
  refetchRoom?: () => void;
  isAuthedUsersEvent?: boolean;
  allowToModifyEvent?: boolean;
  disableEventActions?: boolean;
  checkInTime?: number;
  organizerName?: string;
}

export interface ExtendedProps extends Props {
  isOpen: boolean;
  toggleModal: () => void;
  userName: string;
}

export const ReservationListActions = ({
  type,
  workplaceId,
  event,
  refetchDesk,
  refetchRoom,
  startTime,
  endTime,
  name,
  locationPath,
  checkInStatus,
  qrCodeRequired,
  allowDeleteOption,
  isAttendee,
  isAuthedUsersEvent,
  checkInTime,
  organizerName,
}: Props) => {
  const ref = useRef(null);
  const { checkUserPermission } = useCheckUserPermissions();
  const { history, pathname } = useRouter();
  const { isOpen, toggleModal } = useToggleModal();

  const [showDropdown, setShowDropdown] = useState(false);
  const [activeModal, setActiveModal] = useState<ModalTypes | undefined>(
    undefined
  );
  const [hide, setHide] = useState("d-none");

  const memberView = checkUserPermission(
    RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
  );

  const canModifyEvent = isAuthedUsersEvent || !memberView;

  const disableEventActions = !canModifyEvent || isAttendee;

  const components: {
    [key in ModalTypes]: React.FunctionComponent<any>;
  } = {
    modifyReservation: UpdateReservationModal,
    deleteReservation: DeleteModal,
    checkInDesk: qrCodeRequired ? CheckInRequired : CheckInDeskModal,
    checkOut: CheckOutModal,
  };

  const options: DropdownListOptions[] = getReservationTypes({
    typeOfCheckin: checkInStatus,
    workplaceType: type,
    startTime: event?.startTime,
    isCheckInRequired: event.checkInRequiredStatus,
    allowDeleteOption: allowDeleteOption,
    allDay: event.allDay,
    status: event?.locations[0]?.status,
    isAttendee:
      pathname?.includes("all-reservations") && !memberView
        ? false
        : isAttendee,
    allowToModifyEvent: canModifyEvent,
  });

  const Component = components[activeModal as ModalTypes];

  let componentProps: ExtendedProps = {
    workplaceId: workplaceId,
    name: name,
    userName: organizerName || "",
    refetchDesk: refetchDesk,
    refetchRoom: refetchRoom,
    locationPath: locationPath,
    isOpen: isOpen,
    toggleModal: toggleModal,
    startTime: startTime,
    endTime: endTime,
    event: event,
    type: type,
    checkInStatus: checkInStatus,
    allowDeleteOption: allowDeleteOption,
    isAttendee: isAttendee,
    checkInTime: checkInTime,
    allowToModifyEvent: canModifyEvent,
    disableEventActions: disableEventActions,
  };

  useOutsideClickDetection(ref, () => {
    setHide("d-none");
    setShowDropdown(false);
  });

  return (
    <div ref={ref}>
      <Icon
        icon="actions-icon"
        className={hide}
        onClick={() => {
          if (showDropdown) {
            setHide("d-none");
          }

          setHide("d-block");
          setShowDropdown((prev) => !prev);
        }}
      />

      {showDropdown && (
        <DropdownList
          extended
          options={options}
          color="gray"
          onClick={(value) => {
            if (value === "openMap") {
              let clickedMarker = locationPath.filter(
                (location) => location.type === "Floor"
              );

              if (clickedMarker.length <= 0) {
                return toast.warn("This workspace is not part of a floor!");
              }

              return history.push(
                `${RESERVATIONS_MAP_ROOT_PATH}/${clickedMarker[0].id}/markerOpen=${workplaceId}`
              );
            }

            setActiveModal(value as ModalTypes);

            setShowDropdown(false);

            toggleModal();
          }}
        />
      )}
      {isOpen && <Component {...componentProps}></Component>}
    </div>
  );
};
