import React, { useEffect, useRef } from "react";
import { Circle, FeatureGroup, Polygon, Popup } from "react-leaflet";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { ShapeTooltip } from "./ShapeTooltip";
import { FloorChildrenType } from "../../../Floors/lib/floorData";
import { BookingsMapPopUp } from "../BookingsMapMarkers/BookingsMapPopUp";
import { MapEntityModal } from "../BookingsFloorMap/BookingsFloorMap";

export const BookingsMapShapes = ({
  circles,
  polygonal,
  showRoomMarkers,
  showTooltips,
  handleShapeClick,
  handleMapEntityReservePress,
  room,
}: {
  circles: L.Circle<any>[];
  polygonal: L.Polygon<any>[];
  showRoomMarkers: boolean;
  showTooltips: boolean;
  handleShapeClick: (id: string) => void;
  handleMapEntityReservePress: ({
    id,
    type,
    modalType,
    busySlots,
  }: MapEntityModal) => void;
  room?: FloorChildrenType;
}) => {
  const { user } = useAuthContext();

  const circleRefs = useRef<Array<L.Circle | null>>([]);
  const polygonRefs = useRef<Array<L.Polygon | null>>([]);

  useEffect(() => {
    if (!room) return;

    // open the popup for the correct circle or polygon based on the `room` id
    const circleIndex = circles.findIndex((circle) => {
      const shapeId = circle.options.attribution?.split(
        "-innerGoGetIDSplitter-"
      )[0];
      return shapeId === room.id;
    });

    const polygonIndex = polygonal.findIndex((polygon) => {
      const shapeId = polygon.options.attribution?.split(
        "-innerGoGetIDSplitter-"
      )[0];
      return shapeId === room.id;
    });

    if (circleIndex !== -1 && circleRefs.current[circleIndex]) {
      circleRefs.current[circleIndex]!.openPopup();
    }

    if (polygonIndex !== -1 && polygonRefs.current[polygonIndex]) {
      polygonRefs.current[polygonIndex]!.openPopup();
    }
  }, [room, circles, polygonal]);

  return (
    <>
      <FeatureGroup>
        {circles.map((circle, index) => {
          const shapeId = circle.options.attribution?.split(
            "-innerGoGetIDSplitter-"
          )[0];

          if (!shapeId) return null;

          return (
            <div key={shapeId}>
              <Circle
                ref={(el) => (circleRefs.current[index] = el)}
                center={circle.getLatLng()}
                radius={circle.getRadius()}
                color={circle.options.color}
                fillOpacity={circle.options.fillOpacity}
                fillColor={circle.options.fillColor}
                weight={2}
                eventHandlers={{
                  click: () => {
                    handleShapeClick(shapeId);
                  },
                }}
              >
                {!showRoomMarkers && showTooltips && (
                  <ShapeTooltip
                    name={
                      circle.options.attribution?.split(
                        "-innerGoGetIDSplitter-"
                      )[1] || ""
                    }
                    className={circle.options.className}
                  />
                )}

                {room && room.id === shapeId && (
                  <Popup>
                    <BookingsMapPopUp
                      id={room.id}
                      isBooked={room.isBooked || false}
                      isAssigned={false}
                      isBlocked={room.isBlocked || false}
                      type={"Room"}
                      name={room.name}
                      tags={room.tags}
                      busySlots={room.busySlots || []}
                      email={user?.email}
                      handleOpenModal={() =>
                        handleMapEntityReservePress({
                          id: room.id,
                          busySlots: room.busySlots || [],
                          type: "room",
                          modalType: room.isBooked ? "delete" : "add",
                        })
                      }
                    />
                  </Popup>
                )}
              </Circle>
            </div>
          );
        })}

        {polygonal.map((polygon, index) => {
          const shapeId = polygon.options.attribution?.split(
            "-innerGoGetIDSplitter-"
          )[0];

          if (!shapeId) return null;

          return (
            <div key={shapeId}>
              <Polygon
                ref={(el) => (polygonRefs.current[index] = el)}
                positions={polygon.getLatLngs()}
                color={polygon.options.color}
                fillOpacity={polygon.options.fillOpacity}
                fillColor={polygon.options.fillColor}
                weight={2}
                eventHandlers={{
                  click: () => {
                    handleShapeClick(shapeId);
                  },
                }}
              >
                {!showRoomMarkers && showTooltips && (
                  <ShapeTooltip
                    name={
                      polygon.options.attribution?.split(
                        "-innerGoGetIDSplitter-"
                      )[1] || ""
                    }
                    className={polygon.options.className}
                  />
                )}

                {room && room.id === shapeId && (
                  <Popup>
                    <BookingsMapPopUp
                      id={room.id}
                      isBooked={room.isBooked || false}
                      isAssigned={false}
                      isBlocked={room.isBlocked || false}
                      type={"Room"}
                      name={room.name}
                      tags={room.tags}
                      busySlots={room.busySlots || []}
                      email={user?.email}
                      handleOpenModal={() =>
                        handleMapEntityReservePress({
                          id: room.id,
                          busySlots: room.busySlots || [],
                          type: "room",
                          modalType: room.isBooked ? "delete" : "add",
                        })
                      }
                    />
                  </Popup>
                )}
              </Polygon>
            </div>
          );
        })}
      </FeatureGroup>
    </>
  );
};
