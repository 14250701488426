import React, { useMemo } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useFormatReservationDate as formatReservationDate } from "../../../lib/datePickerHelper";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { useReservationHelpers } from "../../../Reservations/helpers/reservationUpdateHelpers";
import { useDeskReservations } from "../../../Reservations/helpers/useDeskReservations";
import { utcToZonedTime } from "date-fns-tz";

import { BusyTimeSlots } from "../../../../../api/grpc/desk/ggevent/ggevent";
import { dateToHoursAndMinutes } from "../../../lib/dateInputConvert";
import { UserModalLoading } from "../../../shared/UserModal/UserModalLoading";
import { BookingsModalBody } from "../shared/BookingsModalBody";
import { defineLocationPath } from "../../../helpers/defineLocationPath";
import { ModalFooterContent } from "../../../../Modals/ModalFooterContent/ModalFooterContent";

interface Props {
  id: string;
  busySlots: BusyTimeSlots[];
  toggleModal: () => void;
  refetch: () => void;
}

export const BookingsMapDeleteDesk = ({
  id,
  busySlots,
  toggleModal,
  refetch,
}: Props) => {
  const { deleteDeskReservation } = useReservationHelpers();
  const { user } = useAuthContext();
  const { deskData, loadingDesk, errorDesk } = useDeskReservations();
  const { timeZone } = useTimeZoneContext();

  let choosedDesk = deskData?.desks.find((desk) => desk.deskID === id);

  let eventToDelete =
    choosedDesk &&
    choosedDesk?.event.find(
      (event) =>
        event.startTime === busySlots[0].startTime &&
        event.endTime === busySlots[0].endTime
    );

  const isLoading = useMemo<boolean>(() => {
    return loadingDesk || eventToDelete === undefined;
  }, [loadingDesk, eventToDelete]);

  const handleDeskDelete = async () => {
    if (!eventToDelete) {
      return toast.error(
        "Error deleting reservation, please reload and try again"
      );
    }

    return await deleteDeskReservation({
      deskID: id,
      eventID: eventToDelete?.metaData.DESK_EVENT_ID || "",
      accountID: eventToDelete?.organizer?.id || "",
      refetch: refetch,
    });
  };

  return (
    <>
      {isLoading ? (
        <UserModalLoading />
      ) : errorDesk ? (
        <div>Error loading reservation data, please reload and try again</div>
      ) : (
        <>
          <>
            <BookingsModalBody
              title="Delete reservation?"
              icon="delete-reservation"
              workspaceType="desk"
              workspaceName={choosedDesk?.deskName || ""}
              workspaceIconType="desk-reserve"
              day={formatReservationDate(
                utcToZonedTime(
                  new Date(eventToDelete?.startTime || ""),
                  timeZone
                )
              )}
              startTime={dateToHoursAndMinutes(
                new Date(eventToDelete?.startTime || ""),
                timeZone
              )}
              endTime={
                busySlots[0].isAllDay === true
                  ? "00:00"
                  : dateToHoursAndMinutes(
                      new Date(eventToDelete?.endTime || ""),
                      timeZone
                    )
              }
              userName={user?.displayName || ""}
              locationPath={
                choosedDesk ? defineLocationPath(choosedDesk?.locationPath) : []
              }
              type="delete"
              subject={eventToDelete?.title || "Details:"}
              allDay={busySlots[0]?.isAllDay}
            />

            <ModalFooterContent
              className="modal-footer-row p-0 w-90 gap-2 mt-3"
              toggleModal={toggleModal}
              handleSave={handleDeskDelete}
              title="Delete"
              buttonColor="business"
              buttonClassName="m-0 max-w-none flex-1"
            />
          </>
        </>
      )}
    </>
  );
};
