import { useMemo } from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { transport } from "../grpcTransport";
import {
  BookMeetingRequest,
  DeleteMeetingRequest,
  ListPersonalMeetingsRequest,
  RoomsEventsRequest,
  UpdateMeetingRequest,
} from "./booking";
import { BookingClient } from "./booking.client";

export type ListAllRoomsBookingRequest = Omit<RoomsEventsRequest, "customerID">;

export const useBookingRequest = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const bookingClient = useMemo(() => new BookingClient(transport), []);

  const reserveRoom = async ({
    calendarId,
    calendarproviderId,
    roomID,
    meeting,
    onBehalfUserId,
  }: Omit<BookMeetingRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await bookingClient.bookMeeting(
      {
        customerId: user.customerid,
        onBehalfUserId: onBehalfUserId,
        roomID: roomID,
        calendarproviderId: calendarproviderId,
        calendarId: calendarId,
        meeting: meeting,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listBookings = async ({
    interval,
    week,
    limit,
    timeZone,
  }: Omit<ListPersonalMeetingsRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await bookingClient.listPersonalMeetings(
      {
        customerId: user.customerid,
        interval: interval,
        week: week,
        limit: limit,
        timeZone: timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateRoomBookings = async ({
    calendarproviderId,
    calendarId,
    meeting,
    activateCheckOut,
  }: UpdateMeetingRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await bookingClient.updateMeeting(
      {
        customerId: user.customerid,
        calendarproviderId: calendarproviderId,
        calendarId: calendarId,
        meeting: meeting,
        activateCheckOut: activateCheckOut,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteRoomBooking = async ({
    calendarproviderId,
    calendarId,
    metaData,
    systemDelete,
    organizerEmail,
  }: DeleteMeetingRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await bookingClient.deleteMeeting(
      {
        customerId: user.customerid,
        calendarproviderId: calendarproviderId,
        calendarId: calendarId,
        metaData: metaData,
        systemDelete: systemDelete,
        organizerEmail: organizerEmail,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listAllRoomBookings = async ({
    startTime,
    endTime,
    roomsId,
    timeZone,
  }: ListAllRoomsBookingRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await bookingClient.roomsEvents(
      {
        customerID: user.customerid,
        startTime,
        endTime,
        roomsId,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    reserveRoom,
    listBookings,
    updateRoomBookings,
    deleteRoomBooking,
    listAllRoomBookings,
  };
};
