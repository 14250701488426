import React from "react";
import { Popup } from "../../../shared/Popup/Popup";

import "./TimeSelector.scss";

interface Props {
  selectedHour: number | null;
  selectedMinute: number | null;
  onDurationChange: (hour: number, minute: number) => void;
}

export const TimeSelector = ({
  selectedHour,
  selectedMinute,
  onDurationChange,
}: Props) => {
  const displayTime = () => {
    const hourDisplay = selectedHour !== null ? `${selectedHour}h` : "0h";
    const minuteDisplay = selectedMinute !== null ? `${selectedMinute}m` : "";
    return `${hourDisplay} ${minuteDisplay}`;
  };

  const renderPickerColumn = (
    items: number[],
    selectedValue: number | null,
    onClick: (value: number) => void
  ) => {
    const handleItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      value: number
    ) => {
      const itemElement = event.currentTarget;
      itemElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      onClick(value);
    };

    return (
      <div className="TimeSelector__wrapper">
        <div className="TimeSelector__wrapper--column">
          {items.map((item) => (
            <div
              key={item}
              className={`TimeSelector__wrapper--item ${
                selectedValue === item ? "selected" : ""
              }`}
              onClick={(e) => handleItemClick(e, item)}
            >
              {item.toString().padStart(2, "0")}
            </div>
          ))}
        </div>
        <div className="TimeSelector__wrapper--borders" />
      </div>
    );
  };

  return (
    <div className="flex-1">
      <label>For</label>
      <Popup
        trigger={() => (
          <div className="TimeSelector flex-a-center cursor-pointer">
            <span className="pt-1 pl-1 text-14">{displayTime()}</span>
          </div>
        )}
        position="bottom left"
        hideCloseButton
        className="TimeSelector__popup"
      >
        <div className="TimeSelector__content">
          <div className="TimeSelector__content--head">
            <span>Hour</span>
            <span>Minute</span>
          </div>
          <div className="TimeSelector__content--body">
            {renderPickerColumn([...Array(24).keys()], selectedHour, (hour) =>
              onDurationChange(hour, selectedMinute || 0)
            )}
            <div className="TimeSelector__content--colon">:</div>
            {renderPickerColumn(
              [...Array(12).keys()].map((i) => i * 5),
              selectedMinute,
              (minute) => onDurationChange(selectedHour || 0, minute)
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};
