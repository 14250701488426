import React from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useCheckUserPermissions } from "../../../../lib/hooks/useCheckUserPermissions";
import { dateToHoursAndMinutes } from "../../../Bookings/lib/dateInputConvert";

import { ReservationListActions } from "../../../Bookings/Reservations/ReservationList/ReservationListActions";
import { Event as RoomEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as DeskEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { BookingTypes } from "../../../Bookings/BookingsSearch/BookingsSearch";
import { RolePermissions } from "../../../../api/grpc/account/account";
import { ReservationEventStatus } from "./ReservationEventStatus";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";

interface Props {
  event: RoomEvent | DeskEvent;
  type: BookingTypes;
  workplaceID: string;
  workspaceName: string;
  locationPath: DeskLocationItem[];
  qrCodeRequired?: boolean;
  refetchDesk?: () => void;
  refetchRoom?: () => void;
  checkInTime?: number;
}

export const ReservationEvent = ({
  event,
  type,
  workplaceID,
  workspaceName,
  locationPath,
  qrCodeRequired,
  refetchDesk,
  refetchRoom,
  checkInTime,
}: Props) => {
  const { user } = useAuthContext();
  const { checkUserPermission } = useCheckUserPermissions();

  const { timeZone } = useTimeZoneContext();

  //rooms can have organizer stored in attendees
  const organizerFromAttendee = event?.attendees.find(
    (attendee) => attendee.mode1Organizer
  );

  //for desk we no need to check for mode1Organizer
  const eventOrganizer =
    event.organizer?.email === user?.email &&
    (event.organizer?.mode1Organizer || type === "desk");

  const isAuthedUsersEvent =
    eventOrganizer || organizerFromAttendee?.email === user?.email;

  const memberView = checkUserPermission(
    RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
  );

  const defineEventName = () => {
    if (isAuthedUsersEvent) {
      if (organizerFromAttendee) {
        return organizerFromAttendee.displayName + " (You)";
      }

      return event.organizer?.displayName + " (You)";
    }

    if (!memberView) {
      if (organizerFromAttendee) {
        return organizerFromAttendee.displayName;
      }

      return event?.organizer?.displayName;
    }

    if (organizerFromAttendee) {
      return organizerFromAttendee.allowToFind
        ? organizerFromAttendee.displayName
        : "Reserved";
    }

    return event?.organizer?.allowToFind
      ? event.organizer?.displayName
      : "Reserved";
  };

  return (
    <div className="ReservationEvent mt-4 default-box">
      <div className="flex-a-center">
        <span>
          {event.allDay === true
            ? "All day"
            : dateToHoursAndMinutes(new Date(event.startTime), timeZone) +
              " - " +
              dateToHoursAndMinutes(new Date(event.endTime), timeZone)}
        </span>

        <ReservationEventStatus
          type={type}
          isCheckOut={event.isCheckOut}
          allDay={event.allDay}
          startTime={event.startTime}
          endTime={event.endTime}
          status={event.locations[0]?.status}
        />
      </div>
      <h6 className="mt-2 overflow-text">{defineEventName()}</h6>

      {type === "room" &&
        event.title.length > 0 &&
        (isAuthedUsersEvent ||
          !memberView ||
          event?.organizer?.allowToFind) && (
          <span className="text-secondary d-block overflow-text">
            ({event.title})
          </span>
        )}

      {event && (
        <ReservationListActions
          type={type}
          workplaceId={workplaceID}
          event={event}
          startTime={event.startTime}
          endTime={event.endTime}
          name={workspaceName}
          locationPath={locationPath}
          checkInStatus={event.eventCheckInStatus}
          qrCodeRequired={qrCodeRequired}
          isAuthedUsersEvent={isAuthedUsersEvent}
          allowDeleteOption={true}
          // isAttendee={
          //   event.organizer?.mode1Organizer !== true &&
          //   organizerFromAttendee?.email !== user?.email
          // }
          isAttendee={false}
          refetchDesk={refetchDesk}
          refetchRoom={refetchRoom}
          checkInTime={checkInTime}
          organizerName={defineEventName()}
        />
      )}
    </div>
  );
};
