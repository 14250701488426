import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";

import { LocationItem } from "../../../../api/grpc/workplaces/workplaces";
import { tooltips } from "../../../../lib/utils/tooltips";
import { Button } from "../../../shared/Button/Button";
import { Icon, IconDefinition } from "../../../shared/Icon/Icon";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { BookingsModalBody } from "../../BookingsMap/BookingsMapModal/shared/BookingsModalBody";
import { MainResources } from "../../../../lib/types/main.types";
import { RolePermissions } from "../../../../api/grpc/account/account";
import { UserViewType } from "../../../shared/UserViewType/UserViewType";

import "./BookingsModal.scss";

export type ModalType =
  | "confirm"
  | "delete"
  | "check-in"
  | "check-in-required"
  | "update"
  | "check-out"
  | "warning";

interface Props {
  title: string;
  isOpen: boolean;
  toggle: () => void;
  workspaceType: MainResources;
  icon?: IconDefinition;
  workspaceIconType?: IconDefinition;
  workspaceName: string;
  day: string;
  startTime: string;
  endTime: string;
  userName: string;
  subject?: string;
  locationPath: LocationItem[];
  handleModalClose?: () => void;
  type: ModalType;
  buttonTitle?: string;
  buttonClick?: () => void;
  loadSpinner?: boolean;
  loadTitle?: string;
  updateTime?: Date;
  startDate?: Date;
  handleUpdateTime?: (date: Date) => void;
  classNames?: string;
  disableButton?: boolean;
  allDay?: boolean;
  timeZone?: string;
  disableEventActions?: boolean;
  everyDay?: boolean;
  buttonCancelTitle?: string;
  attendees?: string;
}

export const BookingsModal = ({
  title,
  isOpen,
  toggle,
  workspaceType,
  icon,
  workspaceIconType,
  workspaceName,
  day,
  startTime,
  endTime,
  userName,
  subject,
  locationPath,
  handleModalClose,
  type,
  buttonTitle,
  buttonClick,
  loadSpinner,
  loadTitle,
  updateTime,
  startDate,
  handleUpdateTime,
  classNames,
  disableButton,
  allDay,
  disableEventActions,
  everyDay,
  buttonCancelTitle,
  attendees,
}: Props) => {
  const { user } = useAuthContext();

  return (
    <Modal isOpen={isOpen} onToggle={toggle} className="BookingsModal">
      {!user?.permissions?.includes(
        RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
      ) && <UserViewType />}

      <ModalHeader>
        <span onClick={handleModalClose} className="btn-close">
          <Icon icon="close-icon-rounded" />
        </span>
      </ModalHeader>

      <BookingsModalBody
        title={title}
        icon={icon}
        workspaceType={workspaceType}
        workspaceName={workspaceName}
        workspaceIconType={workspaceIconType}
        day={day}
        startTime={startTime}
        endTime={endTime}
        userName={userName}
        locationPath={locationPath}
        type={type}
        updateTime={updateTime}
        startDate={startDate}
        handleUpdateTime={handleUpdateTime}
        allDay={allDay}
        subject={subject}
        disableEventActions={disableEventActions}
        everyDay={everyDay}
        attendees={attendees}
      />

      {type === "confirm" || disableEventActions ? (
        <>
          {!disableEventActions && (
            <div className="flex-a-center mb-2">
              <InformationBox message={tooltips.bookings.info} />
              <span className="label__main pl-min BookingsModal__required">
                Check-in may be required
              </span>
            </div>
          )}

          <ModalFooter className="w-100 p-0 mt-2">
            <Button
              size="small"
              color="business"
              title="OK"
              onClick={handleModalClose}
              className="w-90 max-w-none"
            />
          </ModalFooter>
        </>
      ) : (
        <>
          {type === "warning" && (
            <h5 className="w-90 mb-2 mt-1 text-center">
              Do you still want to reserve another desk?
            </h5>
          )}

          <ModalFooter className={`${classNames} p-0 w-90 gap-2 mt-3`}>
            <Button
              size="small"
              color="outline-secondary"
              title={buttonCancelTitle || "Cancel"}
              onClick={handleModalClose}
              className="m-0 max-w-none flex-1"
            />

            <Button
              size="small"
              color="business"
              title={buttonTitle}
              onClick={buttonClick}
              disabled={disableButton}
              loadSpinner={loadSpinner}
              loadTitle={loadTitle}
              className="m-0 max-w-none flex-1"
            />
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
