import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

import { Event } from "../../../../api/grpc/booking/ggevent/ggevent";
import { ROOM_FIELDS_FRAGMENT } from "../../../../api/graphql/rooms/rooms";

export const useCheckOutRoom = () => {
  const client = useApolloClient();
  const { personalCalendar } = useCalendarContext();
  const { updateRoomBookings } = useBookingRequest();
  const { timeZone } = useTimeZoneContext();

  const checkOutRoom = async (
    id: string,
    event: Event,
    isAuthedUsersEvent?: boolean
  ) => {
    const workplaceData = client.readFragment({
      id: `Room:${id}`,
      fragmentName: "RoomFields",
      fragment: ROOM_FIELDS_FRAGMENT,
    });

    let eventToBeChanged: Event = {
      ...event,
      endTime: new Date().toISOString(),
      eventTimeZone: timeZone,
    };

    try {
      const owner = personalCalendar.length > 0 && isAuthedUsersEvent;

      const calendarId = owner ? "" : workplaceData?.calendarId;

      const calendarproviderId = owner
        ? personalCalendar[0].iD
        : workplaceData?.calendarProviderId;

      await updateRoomBookings({
        customerId: "",
        meeting: eventToBeChanged,
        calendarId,
        calendarproviderId,
        activateCheckOut: false,
      });

      toast.success("Check-out finished successfully");
    } catch (error: any) {
      return toast.error(error?.message ?? "Couldn't check out room");
    }
  };

  return { checkOutRoom };
};
