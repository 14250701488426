import { format } from "date-fns";
import React, { useState } from "react";
import { Person } from "../../../../api/grpc/contacts/contacts";
import { attachHoursToDate } from "../../lib/dateInputConvert";

import { BookingsMapContext, IBookingsMapContext } from "./BookingsMapContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

interface Props {
  children: React.ReactNode;
}

export const BookingsMapContextProvider = ({ children }: Props) => {
  const { timeZone } = useTimeZoneContext();
  // state
  const [time, setTime] = useState(utcToZonedTime(new Date(), timeZone));
  const [dateAndTime, setDateAndTime] = useState(new Date().toISOString());
  const [day, setDay] = useState(format(new Date(), "yyyy-MM-dd"));
  const [bookOnBehalfUser, setBookOnBehalfUser] = useState<Person | undefined>(
    undefined
  );
  const [notifyAssignee, setNotifyAssignee] = useState(false);
  const [allDay, setAllDay] = useState(false);
  const [searchedAttendees, setSearchedAttendees] = useState<Person[]>([]);

  const handleTime = (time: Date) => {
    setTime(time);
  };

  const handleDay = (day: string) => {
    setDay(day);
  };

  const searchByDate = () => {
    let zonedDay = zonedTimeToUtc(day, timeZone);

    setDateAndTime(
      attachHoursToDate(
        utcToZonedTime(zonedDay, timeZone).toISOString(),
        time,
        timeZone
      )
    );
  };

  const handleSearchedAttendees = (attende: Person[]) => {
    setSearchedAttendees([...attende]);
  };

  const context: IBookingsMapContext = {
    time,
    handleTime,
    day,
    handleDay,
    searchByDate,
    dateAndTime,
    setDateAndTime,
    searchedAttendees,
    bookOnBehalfUser,
    setBookOnBehalfUser,
    handleSearchedAttendees,
    allDay,
    setAllDay,
    notifyAssignee,
    setNotifyAssignee,
  };

  return (
    <BookingsMapContext.Provider value={context}>
      {children}
    </BookingsMapContext.Provider>
  );
};
