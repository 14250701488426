import React, { useEffect, useState } from "react";
import { utcToZonedTime } from "date-fns-tz";
import { toast } from "react-toastify";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useLocationDefaultInfo } from "../../helpers/useLocationId";
import { useQuery } from "@apollo/client";
import { useSearchWorkplaceFormik } from "../../lib/useSearchWorkplaceFormik";
import { useBookingsContext } from "../../Context/BookingsContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";
import { forReservationDate } from "../../lib/datePickerHelper";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useAssignedDesks } from "../../lib/useAssignedDesks";

import { ReserveFooter } from "../ReserveFooter";
import {
  attachHoursToDate,
  dateToHoursAndMinutes,
} from "../../lib/dateInputConvert";
import { ReserveCard } from "../../Reserve/ReserveCard";
import { SearchWorkplaceForm } from "../../form/SearchWorkplaceForm";
import { BookingsDesksResult } from "./BookingsDesksResult";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { differenceInMinutes, endOfDay } from "date-fns";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import {
  GetDefaultLocationPathRequestVariables,
  GetDefaultLocationPathResponse,
  GET_DEFAULT_LOCATION_PATH,
} from "../../../../api/graphql/bookings/bookings";
import { Button } from "../../../shared/Button/Button";
import { MatchingDesk } from "../../../../api/grpc/desk/desk";
import { SearchHeaderInfo } from "../BookingsSearchHeader/BookingsSearchHeader";

interface Props {
  type: string;
}

export const BookingsDesksSearch = ({ type }: Props) => {
  const { searchMatchedDesks } = useDeskBookingRequest();
  const { user } = useAuthContext();
  const { timeZone } = useTimeZoneContext();
  const [loading, setLoading] = useState(false);
  const [pagePagination, setPagePagination] = useState(1);

  const { assignedDesks } = useAssignedDesks();

  const {
    matchedDesks,
    handleMatchedDesks,
    location: { locationName },
  } = useBookingsContext();
  const { activeTagsFilter } = useBookingsContext();
  const { allDay, setAllDay } = useBookingsMapContext();
  const [headerInfo, setHeaderInfo] = useState<SearchHeaderInfo>({
    time: "",
    day: "",
    location: "",
  });
  const { history } = useRouter();
  const { _locationId } = useLocationDefaultInfo();
  const defaultLocation = useQuery<
    GetDefaultLocationPathResponse,
    GetDefaultLocationPathRequestVariables
  >(GET_DEFAULT_LOCATION_PATH, {
    fetchPolicy: "network-only",
    variables: {
      accountId: user?.claims.user_id || "",
    },
  });

  useEffect(() => {
    if (allDay === true) {
      setAllDay(false);
    }
  }, []);

  const formik = useSearchWorkplaceFormik({
    onSubmit: async (values) => {
      try {
        const maxEndTime = endOfDay(
          utcToZonedTime(new Date(values.from), timeZone)
        );

        if (new Date(values.until) > maxEndTime) {
          return toast.error("Your time is out of the day");
        }

        setLoading(true);

        const timeDifference = differenceInMinutes(
          utcToZonedTime(new Date(), timeZone),
          values.from
        );

        const validTimePassed = timeDifference <= 5 && timeDifference > 0;

        let startTime = attachHoursToDate(
          values.day.value,
          validTimePassed ? utcToZonedTime(new Date(), timeZone) : values.from,
          timeZone
        );

        let endTime = attachHoursToDate(
          values.day.value,
          values.until,
          timeZone,
          allDay
        );

        if (differenceInMinutes(new Date(), new Date(startTime)) > 5) {
          setLoading(false);
          return toast.error("Invalid From time!");
        }

        if (!allDay && differenceInMinutes(values.until, values.from) < 15) {
          setLoading(false);
          return toast.error("Booking duration can't be less than 15 minutes!");
        }

        let availableDesks: MatchingDesk[] = [];
        let pageFromResponse: number | null = null;

        do {
          const {
            response: { desks, nextPage },
          }: { response: { desks: MatchingDesk[]; nextPage: number } } =
            await searchMatchedDesks({
              startTime: startTime,
              endTime: endTime,
              accountID: user?.claims.user_id,
              locationID: _locationId || "",
              tagsFilter: activeTagsFilter || [],
              customerID: user?.customerid || "",
              timeZone: timeZone,
              page:
                pageFromResponse !== null ? pageFromResponse : pagePagination,
            });

          availableDesks = [...availableDesks, ...desks];
          pageFromResponse = nextPage;
        } while (
          availableDesks.length < 8 &&
          pageFromResponse !== null &&
          pageFromResponse > 0
        );

        if (matchedDesks) {
          handleMatchedDesks([...matchedDesks, ...(availableDesks || [])]);
        } else {
          handleMatchedDesks(availableDesks);
        }

        setPagePagination(pageFromResponse ?? 1);
        const formattedDate = forReservationDate(
          utcToZonedTime(new Date(startTime), timeZone),
          timeZone
        );
        setHeaderInfo({
          day: formattedDate,
          location:
            locationName && locationName !== "RefreshedId"
              ? locationName
              : "All locations",
          time:
            dateToHoursAndMinutes(new Date(startTime), timeZone) +
            "-" +
            dateToHoursAndMinutes(new Date(endTime), timeZone),
        });

        if (window.innerWidth <= 1200) {
          const targetSection = document.querySelector(
            ".BookingsSearch__workplaces"
          );

          if (targetSection) {
            const offsetTop =
              targetSection.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({ top: offsetTop, behavior: "smooth" });
          }
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error.message);
      }
    },
  });

  return (
    <div className="BookingsSearch__layout">
      <ReserveCard
        workplaceType={type}
        footer={
          <ReserveFooter
            onClick={() => {
              if (pagePagination !== 1) {
                //make new request instead of pagination request
                setPagePagination(1);
                handleMatchedDesks([]);
              }

              return formik.handleSubmit();
            }}
            disabled={defaultLocation.loading || loading}
          />
        }
        className="BookingsSearch__layout--search--desks"
      >
        <HandleRequestState
          state={defaultLocation.data === undefined && !defaultLocation.loading}
          placeholder={<CenteredBox>Could't fetch location data</CenteredBox>}
        >
          <SearchWorkplaceForm
            formik={formik}
            type="desk"
            defaultLocation={defaultLocation.data}
            loading={defaultLocation.loading}
          />
        </HandleRequestState>
      </ReserveCard>
      {/* only show loader for first request */}
      {loading && !!!matchedDesks?.length ? (
        <LoadingBox
          count={10}
          className="BookingsSearch__loading"
          minHeight={45}
        />
      ) : matchedDesks ? (
        <div className="BookingsSearch__workplaces">
          <BookingsDesksResult
            desks={matchedDesks}
            info={headerInfo}
            assignedDesk={assignedDesks?.desks[0]}
          />

          {/* loader for new requests */}
          {loading && (
            <LoadingBox
              count={10}
              className="BookingsSearch__loading"
              minHeight={45}
            />
          )}
          {pagePagination > 1 && (
            <div className="flex-a-center BookingsSearch__layout--pagination">
              <Button
                color="outline-primary"
                title="Back"
                size="small"
                className="mt-4 BookingsSearch__layout--pagination--back"
                disabled={loading}
                onClick={() => {
                  return history.push("/bookings");
                }}
              />

              <Button
                color="primary"
                title="More items"
                size="small"
                className="mt-4"
                disabled={loading}
                loadSpinner={loading}
                loadTitle="Loading"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
