// @generated by protobuf-ts 2.9.4 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "account.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FieldMask } from "./google/protobuf/field_mask";
/**
 * @generated from protobuf message DisableManualMFARequest
 */
export interface DisableManualMFARequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message DisableManualMFAResponse
 */
export interface DisableManualMFAResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message SendRecoveryEmailRequest
 */
export interface SendRecoveryEmailRequest {
    /**
     * @generated from protobuf field: string primary_email = 1;
     */
    primaryEmail: string;
    /**
     * @generated from protobuf field: string secondary_email = 2;
     */
    secondaryEmail: string;
}
/**
 * @generated from protobuf message SendRecoveryEmailResponse
 */
export interface SendRecoveryEmailResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message DisableMFARequest
 */
export interface DisableMFARequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
}
/**
 * @generated from protobuf message DisableMFAResponse
 */
export interface DisableMFAResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message RecoveryEmailRequest
 */
export interface RecoveryEmailRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
}
/**
 * @generated from protobuf message RecoveryEmailResponse
 */
export interface RecoveryEmailResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message ContactPasswordResetRequest
 */
export interface ContactPasswordResetRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DeleteAccountRequest
 */
export interface DeleteAccountRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf message VerifyEmailRequest
 */
export interface VerifyEmailRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
    /**
     * @generated from protobuf field: string apiKey = 3;
     */
    apiKey: string;
}
/**
 * @generated from protobuf message DeletePictureRequest
 */
export interface DeletePictureRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 2;
     */
    accountId: string;
}
/**
 * @generated from protobuf message SendActivationEmailRequest
 */
export interface SendActivationEmailRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
}
/**
 * @generated from protobuf message SendActivationEmailResponse
 */
export interface SendActivationEmailResponse {
    /**
     * @generated from protobuf field: bool status = 1;
     */
    status: boolean;
}
/**
 * @generated from protobuf message EnableAccountRequest
 */
export interface EnableAccountRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 2;
     */
    accountId: string;
}
/**
 * @generated from protobuf message DisableAccountRequest
 */
export interface DisableAccountRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 2;
     */
    accountId: string;
    /**
     * @generated from protobuf field: bool sso_disabled = 3;
     */
    ssoDisabled: boolean;
}
/**
 * @generated from protobuf message PictureRequest
 */
export interface PictureRequest {
    /**
     * @generated from protobuf field: bytes imageData = 1;
     */
    imageData: Uint8Array;
    /**
     * @generated from protobuf field: string imageType = 2;
     */
    imageType: string;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 4;
     */
    accountId: string;
    /**
     * @generated from protobuf field: bytes base64 = 5;
     */
    base64: Uint8Array;
    /**
     * @generated from protobuf field: string url = 6;
     */
    url: string;
}
/**
 * @generated from protobuf message PictureResponse
 */
export interface PictureResponse {
    /**
     * @generated from protobuf field: string image_url = 1;
     */
    imageUrl: string;
}
/**
 * @generated from protobuf message CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * The parent resource where this account will be created.
     * Format: customers/{customer_id}
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * The account to create.
     *
     * @generated from protobuf field: Account account = 2;
     */
    account?: Account;
    /**
     * @generated from protobuf field: string password = 3;
     */
    password: string;
}
/**
 * @generated from protobuf message GetAccountRequest
 */
export interface GetAccountRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 2;
     */
    accountId: string;
}
/**
 * @generated from protobuf message UpdateAllowToFindRequest
 */
export interface UpdateAllowToFindRequest {
    /**
     * @generated from protobuf field: bool allow_to_find = 1;
     */
    allowToFind: boolean;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 3;
     */
    accountId: string;
}
/**
 * @generated from protobuf message UpdateTimeZoneRequest
 */
export interface UpdateTimeZoneRequest {
    /**
     * @generated from protobuf field: string time_zone = 1;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 3;
     */
    accountId: string;
}
/**
 * @generated from protobuf message UpdateAccountRequest
 */
export interface UpdateAccountRequest {
    /**
     * The account to update.
     *
     * The accounts's `name` field is used to identify the account to be updated.
     * Format: customers/{customer}/accounts/{account}
     *
     * @generated from protobuf field: UpdateAccount account = 1;
     */
    account?: UpdateAccount;
    /**
     * The list of fields to be updated.
     *
     * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
     */
    updateMask?: FieldMask;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 4;
     */
    accountId: string;
}
/**
 * @generated from protobuf message UpdateAccount
 */
export interface UpdateAccount {
    /**
     * @generated from protobuf field: string display_name = 1;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string phone_number = 3;
     */
    phoneNumber: string;
    /**
     * @generated from protobuf field: string photo_url = 4;
     */
    photoUrl: string;
    /**
     * @generated from protobuf field: string new_password = 5;
     */
    newPassword: string;
    /**
     * @generated from protobuf field: string first_name = 6;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 7;
     */
    lastName: string;
    /**
     * @generated from protobuf field: bool google_account_connected = 8;
     */
    googleAccountConnected: boolean;
    /**
     * @generated from protobuf field: bool o365_account_connected = 9;
     */
    o365AccountConnected: boolean;
    /**
     * @generated from protobuf field: string google_account_linked_email = 10;
     */
    googleAccountLinkedEmail: string;
    /**
     * @generated from protobuf field: string o365_account_linked_email = 11;
     */
    o365AccountLinkedEmail: string;
    /**
     * @generated from protobuf field: string time_zone = 12;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: MFAType mfa_type = 13;
     */
    mfaType: MFAType;
    /**
     * @generated from protobuf field: string recovery_email = 14;
     */
    recoveryEmail: string;
}
/**
 * @generated from protobuf message ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message FavoriteWorkplace
 */
export interface FavoriteWorkplace {
    /**
     * @generated from protobuf field: FavoriteWorkplaceEnum type = 1;
     */
    type: FavoriteWorkplaceEnum;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf message Account
 */
export interface Account {
    /**
     * The display name the the user will see when they login to thier account
     *
     * @generated from protobuf field: string display_name = 1;
     */
    displayName: string;
    /**
     * The email associated with this account
     *
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * The phone number used for the account
     *
     * @generated from protobuf field: string phone_number = 3;
     */
    phoneNumber: string;
    /**
     * The url to the avatar for this account
     *
     * @generated from protobuf field: string photo_url = 4;
     */
    photoUrl: string;
    /**
     * The customer that this account is associated with
     *
     * @generated from protobuf field: string customer_id = 5;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string first_name = 6;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 7;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string id = 8;
     */
    id: string;
    /**
     * @generated from protobuf field: Role role = 9;
     */
    role: Role;
    /**
     * @generated from protobuf field: WorkScheduleStatus schedule_status = 10;
     */
    scheduleStatus: WorkScheduleStatus;
    /**
     * @generated from protobuf field: bool allow_to_find = 11;
     */
    allowToFind: boolean;
    /**
     * @generated from protobuf field: bool google_account_connected = 12;
     */
    googleAccountConnected: boolean;
    /**
     * @generated from protobuf field: bool o365_account_connected = 13;
     */
    o365AccountConnected: boolean;
    /**
     * @generated from protobuf field: LoginMethod login_method = 14;
     */
    loginMethod: LoginMethod;
    /**
     * @generated from protobuf field: string google_account_linked_email = 15;
     */
    googleAccountLinkedEmail: string;
    /**
     * @generated from protobuf field: string o365_account_linked_email = 16;
     */
    o365AccountLinkedEmail: string;
    /**
     * @generated from protobuf field: string time_zone = 17;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: MFAType mfa_type = 18;
     */
    mfaType: MFAType;
    /**
     * @generated from protobuf field: string recovery_email = 19;
     */
    recoveryEmail: string;
    /**
     * Role Permissions list with whitelisted items
     *
     * @generated from protobuf field: repeated RolePermissions permissions = 20;
     */
    permissions: RolePermissions[];
    /**
     * FavoriteWorkplace show the list of user favorites rooms and desks
     *
     * @generated from protobuf field: repeated FavoriteWorkplace favoriteWorkplaces = 21;
     */
    favoriteWorkplaces: FavoriteWorkplace[];
}
/**
 * @generated from protobuf message SetDeviceIDRequest
 */
export interface SetDeviceIDRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string deviceID = 3;
     */
    deviceID: string;
}
/**
 * @generated from protobuf message PushNotificationsSubscribeRequest
 */
export interface PushNotificationsSubscribeRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message PushNotificationsSubscribeResponse
 */
export interface PushNotificationsSubscribeResponse {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message PushNotificationsUnsubscribeRequest
 */
export interface PushNotificationsUnsubscribeRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message WorkingSchedule
 */
export interface WorkingSchedule {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: WorkScheduleStatus workingStatus = 2;
     */
    workingStatus: WorkScheduleStatus;
    /**
     * @generated from protobuf field: string date = 3;
     */
    date: string;
    /**
     * @generated from protobuf field: string startTime = 4;
     */
    startTime: string;
    /**
     * @generated from protobuf field: string endTime = 5;
     */
    endTime: string;
}
/**
 * @generated from protobuf message SetWorkingScheduleRequest
 */
export interface SetWorkingScheduleRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated WorkingSchedule schedule = 3;
     */
    schedule: WorkingSchedule[];
}
/**
 * @generated from protobuf message GetWorkingScheduleRequest
 */
export interface GetWorkingScheduleRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string startDate = 3;
     */
    startDate: string; // should be a in format 2022-05-18
    /**
     * @generated from protobuf field: string endDate = 4;
     */
    endDate: string; // should be a in format 2022-05-18
}
/**
 * @generated from protobuf message DeleteWorkingScheduleRequest
 */
export interface DeleteWorkingScheduleRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message WorkingScheduleResponse
 */
export interface WorkingScheduleResponse {
    /**
     * @generated from protobuf field: repeated WorkingSchedule schedule = 1;
     */
    schedule: WorkingSchedule[];
}
/**
 * @generated from protobuf message VerifyMobileAPPAccountRequest
 */
export interface VerifyMobileAPPAccountRequest {
    /**
     * @generated from protobuf field: string accountEmail = 1;
     */
    accountEmail: string;
    /**
     * @generated from protobuf field: string deviceID = 2;
     */
    deviceID: string;
}
/**
 * @generated from protobuf message VerifyMobileAPPAccountResponse
 */
export interface VerifyMobileAPPAccountResponse {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message VerifyMobileAPPDeviceRequest
 */
export interface VerifyMobileAPPDeviceRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string deviceID = 2;
     */
    deviceID: string;
}
/**
 * @generated from protobuf message VerifyMobileAPPDeviceResponse
 */
export interface VerifyMobileAPPDeviceResponse {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message RequestEmailChangeRequest
 */
export interface RequestEmailChangeRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string accountID = 2;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string newEmail = 3;
     */
    newEmail: string;
}
/**
 * @generated from protobuf message VerifyEmailChangeRequest
 */
export interface VerifyEmailChangeRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string token = 3;
     */
    token: string;
}
/**
 * @generated from protobuf enum Role
 */
export enum Role {
    /**
     * @generated from protobuf enum value: UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: ADMIN = 1;
     */
    ADMIN = 1,
    /**
     * @generated from protobuf enum value: MEMBER = 2;
     */
    MEMBER = 2,
    /**
     * @generated from protobuf enum value: OWNER = 3;
     */
    OWNER = 3,
    /**
     * @generated from protobuf enum value: SUPPORT = 4;
     */
    SUPPORT = 4,
    /**
     * @generated from protobuf enum value: COORDINATOR = 5;
     */
    COORDINATOR = 5
}
/**
 * @generated from protobuf enum Status
 */
export enum Status {
    /**
     * @generated from protobuf enum value: UNKNOWN_STATUS = 0;
     */
    UNKNOWN_STATUS = 0,
    /**
     * @generated from protobuf enum value: INACTIVE = 1;
     */
    INACTIVE = 1,
    /**
     * @generated from protobuf enum value: PENDING = 2;
     */
    PENDING = 2,
    /**
     * @generated from protobuf enum value: ACTIVE = 3;
     */
    ACTIVE = 3
}
/**
 * @generated from protobuf enum WorkScheduleStatus
 */
export enum WorkScheduleStatus {
    /**
     * @generated from protobuf enum value: SCHEDULE_STATUS_UNKNOWN = 0;
     */
    SCHEDULE_STATUS_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: SCHEDULE_STATUS_AT_THE_OFFICE = 1;
     */
    SCHEDULE_STATUS_AT_THE_OFFICE = 1,
    /**
     * @generated from protobuf enum value: SCHEDULE_STATUS_WORKING_REMOTELY = 2;
     */
    SCHEDULE_STATUS_WORKING_REMOTELY = 2,
    /**
     * @generated from protobuf enum value: SCHEDULE_STATUS_NOT_AT_WORK = 3;
     */
    SCHEDULE_STATUS_NOT_AT_WORK = 3,
    /**
     * @generated from protobuf enum value: SCHEDULE_STATUS_VACATIONING = 4;
     */
    SCHEDULE_STATUS_VACATIONING = 4,
    /**
     * @generated from protobuf enum value: SCHEDULE_STATUS_OUT_SICK = 5;
     */
    SCHEDULE_STATUS_OUT_SICK = 5
}
/**
 * @generated from protobuf enum LoginMethod
 */
export enum LoginMethod {
    /**
     * @generated from protobuf enum value: LOGIN_METHOD_ALL = 0;
     */
    ALL = 0,
    /**
     * @generated from protobuf enum value: LOGIN_METHOD_EMAIL = 1;
     */
    EMAIL = 1,
    /**
     * @generated from protobuf enum value: LOGIN_METHOD_GSUITE = 2;
     */
    GSUITE = 2,
    /**
     * @generated from protobuf enum value: LOGIN_METHOD_O365 = 3;
     */
    O365 = 3
}
/**
 * @generated from protobuf enum FavoriteWorkplaceEnum
 */
export enum FavoriteWorkplaceEnum {
    /**
     * @generated from protobuf enum value: FavoriteWorkplace_UNKNOWN = 0;
     */
    FavoriteWorkplace_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: FavoriteWorkplace_ROOM = 1;
     */
    FavoriteWorkplace_ROOM = 1,
    /**
     * @generated from protobuf enum value: FavoriteWorkplace_DESK = 2;
     */
    FavoriteWorkplace_DESK = 2
}
/**
 * @generated from protobuf enum MFAType
 */
export enum MFAType {
    /**
     * @generated from protobuf enum value: MFA_TYPE_UNKNOWN = 0;
     */
    MFA_TYPE_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: MFA_TYPE_TOTP = 1;
     */
    MFA_TYPE_TOTP = 1,
    /**
     * @generated from protobuf enum value: MFA_TYPE_SMS = 2;
     */
    MFA_TYPE_SMS = 2
}
/**
 * @generated from protobuf enum RolePermissions
 */
export enum RolePermissions {
    /**
     * @generated from protobuf enum value: DASHBOARD_ACCOUNT_VIEW = 0;
     */
    DASHBOARD_ACCOUNT_VIEW = 0,
    /**
     * @generated from protobuf enum value: DASHBOARD_HELP_CENTER = 1;
     */
    DASHBOARD_HELP_CENTER = 1,
    /**
     * @generated from protobuf enum value: DASHBOARD_LEAVE_FEEDBACK = 2;
     */
    DASHBOARD_LEAVE_FEEDBACK = 2,
    /**
     * @generated from protobuf enum value: DASHBOARD_LOGOUT = 3;
     */
    DASHBOARD_LOGOUT = 3,
    /**
     * @generated from protobuf enum value: DASHBOARD_VIEW_EDIT = 4;
     */
    DASHBOARD_VIEW_EDIT = 4,
    /**
     * @generated from protobuf enum value: INSIGHTS_DESK_EXPORT_CSV = 5;
     */
    INSIGHTS_DESK_EXPORT_CSV = 5,
    /**
     * @generated from protobuf enum value: INSIGHTS_DESK_VIEW = 6;
     */
    INSIGHTS_DESK_VIEW = 6,
    /**
     * @generated from protobuf enum value: INSIGHTS_ROOM_EXPORT_CSV = 7;
     */
    INSIGHTS_ROOM_EXPORT_CSV = 7,
    /**
     * @generated from protobuf enum value: INSIGHTS_ROOM_VIEW = 8;
     */
    INSIGHTS_ROOM_VIEW = 8,
    /**
     * @generated from protobuf enum value: MANAGE_CALENDARS_VIEW_EDIT = 9;
     */
    MANAGE_CALENDARS_VIEW_EDIT = 9,
    /**
     * @generated from protobuf enum value: MANAGE_DEVICES_VIEW_EDIT = 10;
     */
    MANAGE_DEVICES_VIEW_EDIT = 10,
    /**
     * @generated from protobuf enum value: MANAGE_MAPS_VIEW_EDIT = 11;
     */
    MANAGE_MAPS_VIEW_EDIT = 11,
    /**
     * @generated from protobuf enum value: MANAGE_WORKSPACES_VIEW_EDIT = 12;
     */
    MANAGE_WORKSPACES_VIEW_EDIT = 12,
    /**
     * @generated from protobuf enum value: ORGANIZATION_SETTINGS_BILLING_VIEW_EDIT = 13;
     */
    ORGANIZATION_SETTINGS_BILLING_VIEW_EDIT = 13,
    /**
     * @generated from protobuf enum value: ORGANIZATION_SETTINGS_GENERAL_VIEW_EDIT = 14;
     */
    ORGANIZATION_SETTINGS_GENERAL_VIEW_EDIT = 14,
    /**
     * @generated from protobuf enum value: ORGANIZATION_SETTINGS_SUBSCRIPTIONS_VIEW_EDIT = 15;
     */
    ORGANIZATION_SETTINGS_SUBSCRIPTIONS_VIEW_EDIT = 15,
    /**
     * @generated from protobuf enum value: ORGANIZATION_SETTINGS_TEMPLATES_VIEW_EDIT = 16;
     */
    ORGANIZATION_SETTINGS_TEMPLATES_VIEW_EDIT = 16,
    /**
     * @generated from protobuf enum value: PROFILE_PICTURE_ADD_EDIT = 17;
     */
    PROFILE_PICTURE_ADD_EDIT = 17,
    /**
     * @generated from protobuf enum value: PROFILE_TWO_FACTOR_AUTH_ADD = 18;
     */
    PROFILE_TWO_FACTOR_AUTH_ADD = 18,
    /**
     * @generated from protobuf enum value: PROFILE_VIEW_EDIT = 19;
     */
    PROFILE_VIEW_EDIT = 19,
    /**
     * @generated from protobuf enum value: RESERVATIONS_ALL_EDIT = 20;
     */
    RESERVATIONS_ALL_EDIT = 20,
    /**
     * @generated from protobuf enum value: RESERVATIONS_ALL_VIEW = 21;
     */
    RESERVATIONS_ALL_VIEW = 21,
    /**
     * @generated from protobuf enum value: RESERVATIONS_COORDINATOR_OWNER_STYLE_BOOKING_MODALS_VIEW = 22;
     */
    RESERVATIONS_COORDINATOR_OWNER_STYLE_BOOKING_MODALS_VIEW = 22,
    /**
     * @generated from protobuf enum value: RESERVATIONS_COORDINATOR_OWNER_STYLE_VIEW = 23;
     */
    RESERVATIONS_COORDINATOR_OWNER_STYLE_VIEW = 23,
    /**
     * @generated from protobuf enum value: RESERVATIONS_DESK_EVENTS_DROPDOWN_EDIT = 24;
     */
    RESERVATIONS_DESK_EVENTS_DROPDOWN_EDIT = 24,
    /**
     * @generated from protobuf enum value: RESERVATIONS_DESK_EVENTS_DROPDOWN_VIEW = 25;
     */
    RESERVATIONS_DESK_EVENTS_DROPDOWN_VIEW = 25,
    /**
     * @generated from protobuf enum value: RESERVATIONS_EVENT_BEHALF_CREATE = 26;
     */
    RESERVATIONS_EVENT_BEHALF_CREATE = 26,
    /**
     * @generated from protobuf enum value: RESERVATIONS_MEMBER_STYLE_BOOKING_MODALS_VIEW = 27;
     */
    RESERVATIONS_MEMBER_STYLE_BOOKING_MODALS_VIEW = 27,
    /**
     * @generated from protobuf enum value: RESERVATIONS_MEMBER_STYLE_VIEW = 28;
     */
    RESERVATIONS_MEMBER_STYLE_VIEW = 28,
    /**
     * @generated from protobuf enum value: RESERVATIONS_ROOM_EVENTS_DROPDOWN_EDIT = 29;
     */
    RESERVATIONS_ROOM_EVENTS_DROPDOWN_EDIT = 29,
    /**
     * @generated from protobuf enum value: RESERVATIONS_ROOM_EVENTS_DROPDOWN_VIEW = 30;
     */
    RESERVATIONS_ROOM_EVENTS_DROPDOWN_VIEW = 30,
    /**
     * @generated from protobuf enum value: RESERVE_ALL_RESERVATIONS_VIEW = 31;
     */
    RESERVE_ALL_RESERVATIONS_VIEW = 31,
    /**
     * @generated from protobuf enum value: RESERVE_FIND_AND_RESERVE_VIEW_EDIT = 32;
     */
    RESERVE_FIND_AND_RESERVE_VIEW_EDIT = 32,
    /**
     * @generated from protobuf enum value: RESERVE_MAP_VIEW = 33;
     */
    RESERVE_MAP_VIEW = 33,
    /**
     * @generated from protobuf enum value: RESERVE_MY_RESERVATIONS_VIEW_EDIT = 34;
     */
    RESERVE_MY_RESERVATIONS_VIEW_EDIT = 34,
    /**
     * @generated from protobuf enum value: RESERVE_SETTINGS_VIEW_EDIT = 35;
     */
    RESERVE_SETTINGS_VIEW_EDIT = 35,
    /**
     * @generated from protobuf enum value: USERS_DIR_SYNC_VIEW_EDIT = 36;
     */
    USERS_DIR_SYNC_VIEW_EDIT = 36,
    /**
     * @generated from protobuf enum value: USERS_PEOPLE_VIEW_EDIT = 37;
     */
    USERS_PEOPLE_VIEW_EDIT = 37,
    /**
     * @generated from protobuf enum value: USERS_TEAMS_VIEW_EDIT = 38;
     */
    USERS_TEAMS_VIEW_EDIT = 38,
    /**
     * @generated from protobuf enum value: MODALS_TIMEZONE_SELECTOR_VIEW_EDIT = 39;
     */
    MODALS_TIMEZONE_SELECTOR_VIEW_EDIT = 39
}
// @generated message type with reflection information, may provide speed optimized methods
class DisableManualMFARequest$Type extends MessageType<DisableManualMFARequest> {
    constructor() {
        super("DisableManualMFARequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DisableManualMFARequest>): DisableManualMFARequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<DisableManualMFARequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableManualMFARequest): DisableManualMFARequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableManualMFARequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisableManualMFARequest
 */
export const DisableManualMFARequest = new DisableManualMFARequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableManualMFAResponse$Type extends MessageType<DisableManualMFAResponse> {
    constructor() {
        super("DisableManualMFAResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DisableManualMFAResponse>): DisableManualMFAResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.result = false;
        if (value !== undefined)
            reflectionMergePartial<DisableManualMFAResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableManualMFAResponse): DisableManualMFAResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool result */ 1:
                    message.result = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableManualMFAResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool result = 1; */
        if (message.result !== false)
            writer.tag(1, WireType.Varint).bool(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisableManualMFAResponse
 */
export const DisableManualMFAResponse = new DisableManualMFAResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendRecoveryEmailRequest$Type extends MessageType<SendRecoveryEmailRequest> {
    constructor() {
        super("SendRecoveryEmailRequest", [
            { no: 1, name: "primary_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "secondary_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendRecoveryEmailRequest>): SendRecoveryEmailRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.primaryEmail = "";
        message.secondaryEmail = "";
        if (value !== undefined)
            reflectionMergePartial<SendRecoveryEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendRecoveryEmailRequest): SendRecoveryEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string primary_email */ 1:
                    message.primaryEmail = reader.string();
                    break;
                case /* string secondary_email */ 2:
                    message.secondaryEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendRecoveryEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string primary_email = 1; */
        if (message.primaryEmail !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.primaryEmail);
        /* string secondary_email = 2; */
        if (message.secondaryEmail !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.secondaryEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SendRecoveryEmailRequest
 */
export const SendRecoveryEmailRequest = new SendRecoveryEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendRecoveryEmailResponse$Type extends MessageType<SendRecoveryEmailResponse> {
    constructor() {
        super("SendRecoveryEmailResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SendRecoveryEmailResponse>): SendRecoveryEmailResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.result = false;
        if (value !== undefined)
            reflectionMergePartial<SendRecoveryEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendRecoveryEmailResponse): SendRecoveryEmailResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool result */ 1:
                    message.result = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendRecoveryEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool result = 1; */
        if (message.result !== false)
            writer.tag(1, WireType.Varint).bool(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SendRecoveryEmailResponse
 */
export const SendRecoveryEmailResponse = new SendRecoveryEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableMFARequest$Type extends MessageType<DisableMFARequest> {
    constructor() {
        super("DisableMFARequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DisableMFARequest>): DisableMFARequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<DisableMFARequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableMFARequest): DisableMFARequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableMFARequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisableMFARequest
 */
export const DisableMFARequest = new DisableMFARequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableMFAResponse$Type extends MessageType<DisableMFAResponse> {
    constructor() {
        super("DisableMFAResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DisableMFAResponse>): DisableMFAResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.result = false;
        if (value !== undefined)
            reflectionMergePartial<DisableMFAResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableMFAResponse): DisableMFAResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool result */ 1:
                    message.result = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableMFAResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool result = 1; */
        if (message.result !== false)
            writer.tag(1, WireType.Varint).bool(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisableMFAResponse
 */
export const DisableMFAResponse = new DisableMFAResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecoveryEmailRequest$Type extends MessageType<RecoveryEmailRequest> {
    constructor() {
        super("RecoveryEmailRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RecoveryEmailRequest>): RecoveryEmailRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<RecoveryEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecoveryEmailRequest): RecoveryEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecoveryEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RecoveryEmailRequest
 */
export const RecoveryEmailRequest = new RecoveryEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecoveryEmailResponse$Type extends MessageType<RecoveryEmailResponse> {
    constructor() {
        super("RecoveryEmailResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RecoveryEmailResponse>): RecoveryEmailResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.result = false;
        if (value !== undefined)
            reflectionMergePartial<RecoveryEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecoveryEmailResponse): RecoveryEmailResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool result */ 1:
                    message.result = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecoveryEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool result = 1; */
        if (message.result !== false)
            writer.tag(1, WireType.Varint).bool(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RecoveryEmailResponse
 */
export const RecoveryEmailResponse = new RecoveryEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactPasswordResetRequest$Type extends MessageType<ContactPasswordResetRequest> {
    constructor() {
        super("ContactPasswordResetRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactPasswordResetRequest>): ContactPasswordResetRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<ContactPasswordResetRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactPasswordResetRequest): ContactPasswordResetRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactPasswordResetRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContactPasswordResetRequest
 */
export const ContactPasswordResetRequest = new ContactPasswordResetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAccountRequest$Type extends MessageType<DeleteAccountRequest> {
    constructor() {
        super("DeleteAccountRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteAccountRequest>): DeleteAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAccountRequest): DeleteAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteAccountRequest
 */
export const DeleteAccountRequest = new DeleteAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyEmailRequest$Type extends MessageType<VerifyEmailRequest> {
    constructor() {
        super("VerifyEmailRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "apiKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyEmailRequest>): VerifyEmailRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.token = "";
        message.apiKey = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyEmailRequest): VerifyEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                case /* string apiKey */ 3:
                    message.apiKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        /* string apiKey = 3; */
        if (message.apiKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.apiKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyEmailRequest
 */
export const VerifyEmailRequest = new VerifyEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePictureRequest$Type extends MessageType<DeletePictureRequest> {
    constructor() {
        super("DeletePictureRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeletePictureRequest>): DeletePictureRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<DeletePictureRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeletePictureRequest): DeletePictureRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 2:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeletePictureRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeletePictureRequest
 */
export const DeletePictureRequest = new DeletePictureRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendActivationEmailRequest$Type extends MessageType<SendActivationEmailRequest> {
    constructor() {
        super("SendActivationEmailRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendActivationEmailRequest>): SendActivationEmailRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<SendActivationEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendActivationEmailRequest): SendActivationEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendActivationEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SendActivationEmailRequest
 */
export const SendActivationEmailRequest = new SendActivationEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendActivationEmailResponse$Type extends MessageType<SendActivationEmailResponse> {
    constructor() {
        super("SendActivationEmailResponse", [
            { no: 1, name: "status", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SendActivationEmailResponse>): SendActivationEmailResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = false;
        if (value !== undefined)
            reflectionMergePartial<SendActivationEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendActivationEmailResponse): SendActivationEmailResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool status */ 1:
                    message.status = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendActivationEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool status = 1; */
        if (message.status !== false)
            writer.tag(1, WireType.Varint).bool(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SendActivationEmailResponse
 */
export const SendActivationEmailResponse = new SendActivationEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnableAccountRequest$Type extends MessageType<EnableAccountRequest> {
    constructor() {
        super("EnableAccountRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EnableAccountRequest>): EnableAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<EnableAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnableAccountRequest): EnableAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 2:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnableAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EnableAccountRequest
 */
export const EnableAccountRequest = new EnableAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableAccountRequest$Type extends MessageType<DisableAccountRequest> {
    constructor() {
        super("DisableAccountRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sso_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DisableAccountRequest>): DisableAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.accountId = "";
        message.ssoDisabled = false;
        if (value !== undefined)
            reflectionMergePartial<DisableAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableAccountRequest): DisableAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 2:
                    message.accountId = reader.string();
                    break;
                case /* bool sso_disabled */ 3:
                    message.ssoDisabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* bool sso_disabled = 3; */
        if (message.ssoDisabled !== false)
            writer.tag(3, WireType.Varint).bool(message.ssoDisabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisableAccountRequest
 */
export const DisableAccountRequest = new DisableAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PictureRequest$Type extends MessageType<PictureRequest> {
    constructor() {
        super("PictureRequest", [
            { no: 1, name: "imageData", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "imageType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "base64", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PictureRequest>): PictureRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imageData = new Uint8Array(0);
        message.imageType = "";
        message.customerId = "";
        message.accountId = "";
        message.base64 = new Uint8Array(0);
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<PictureRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PictureRequest): PictureRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes imageData */ 1:
                    message.imageData = reader.bytes();
                    break;
                case /* string imageType */ 2:
                    message.imageType = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 4:
                    message.accountId = reader.string();
                    break;
                case /* bytes base64 */ 5:
                    message.base64 = reader.bytes();
                    break;
                case /* string url */ 6:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PictureRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes imageData = 1; */
        if (message.imageData.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.imageData);
        /* string imageType = 2; */
        if (message.imageType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.imageType);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 4; */
        if (message.accountId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountId);
        /* bytes base64 = 5; */
        if (message.base64.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.base64);
        /* string url = 6; */
        if (message.url !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PictureRequest
 */
export const PictureRequest = new PictureRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PictureResponse$Type extends MessageType<PictureResponse> {
    constructor() {
        super("PictureResponse", [
            { no: 1, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PictureResponse>): PictureResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imageUrl = "";
        if (value !== undefined)
            reflectionMergePartial<PictureResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PictureResponse): PictureResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string image_url */ 1:
                    message.imageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PictureResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string image_url = 1; */
        if (message.imageUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.imageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PictureResponse
 */
export const PictureResponse = new PictureResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAccountRequest$Type extends MessageType<CreateAccountRequest> {
    constructor() {
        super("CreateAccountRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { childType: "auth/Account" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "account", kind: "message", T: () => Account, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAccountRequest>): CreateAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.password = "";
        if (value !== undefined)
            reflectionMergePartial<CreateAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAccountRequest): CreateAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* Account account */ 2:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* string password */ 3:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* Account account = 2; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string password = 3; */
        if (message.password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateAccountRequest
 */
export const CreateAccountRequest = new CreateAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountRequest$Type extends MessageType<GetAccountRequest> {
    constructor() {
        super("GetAccountRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountRequest>): GetAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<GetAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountRequest): GetAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 2:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetAccountRequest
 */
export const GetAccountRequest = new GetAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAllowToFindRequest$Type extends MessageType<UpdateAllowToFindRequest> {
    constructor() {
        super("UpdateAllowToFindRequest", [
            { no: 1, name: "allow_to_find", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateAllowToFindRequest>): UpdateAllowToFindRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.allowToFind = false;
        message.customerId = "";
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateAllowToFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAllowToFindRequest): UpdateAllowToFindRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool allow_to_find */ 1:
                    message.allowToFind = reader.bool();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 3:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAllowToFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool allow_to_find = 1; */
        if (message.allowToFind !== false)
            writer.tag(1, WireType.Varint).bool(message.allowToFind);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 3; */
        if (message.accountId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateAllowToFindRequest
 */
export const UpdateAllowToFindRequest = new UpdateAllowToFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTimeZoneRequest$Type extends MessageType<UpdateTimeZoneRequest> {
    constructor() {
        super("UpdateTimeZoneRequest", [
            { no: 1, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateTimeZoneRequest>): UpdateTimeZoneRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.timeZone = "";
        message.customerId = "";
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateTimeZoneRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateTimeZoneRequest): UpdateTimeZoneRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string time_zone */ 1:
                    message.timeZone = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 3:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateTimeZoneRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string time_zone = 1; */
        if (message.timeZone !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.timeZone);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 3; */
        if (message.accountId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateTimeZoneRequest
 */
export const UpdateTimeZoneRequest = new UpdateTimeZoneRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAccountRequest$Type extends MessageType<UpdateAccountRequest> {
    constructor() {
        super("UpdateAccountRequest", [
            { no: 1, name: "account", kind: "message", T: () => UpdateAccount, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "update_mask", kind: "message", T: () => FieldMask },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateAccountRequest>): UpdateAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAccountRequest): UpdateAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* UpdateAccount account */ 1:
                    message.account = UpdateAccount.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* google.protobuf.FieldMask update_mask */ 2:
                    message.updateMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.updateMask);
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 4:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* UpdateAccount account = 1; */
        if (message.account)
            UpdateAccount.internalBinaryWrite(message.account, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.FieldMask update_mask = 2; */
        if (message.updateMask)
            FieldMask.internalBinaryWrite(message.updateMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 4; */
        if (message.accountId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateAccountRequest
 */
export const UpdateAccountRequest = new UpdateAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAccount$Type extends MessageType<UpdateAccount> {
    constructor() {
        super("UpdateAccount", [
            { no: 1, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "photo_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "new_password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "google_account_connected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "o365_account_connected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "google_account_linked_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "o365_account_linked_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "mfa_type", kind: "enum", T: () => ["MFAType", MFAType] },
            { no: 14, name: "recovery_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateAccount>): UpdateAccount {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.displayName = "";
        message.email = "";
        message.phoneNumber = "";
        message.photoUrl = "";
        message.newPassword = "";
        message.firstName = "";
        message.lastName = "";
        message.googleAccountConnected = false;
        message.o365AccountConnected = false;
        message.googleAccountLinkedEmail = "";
        message.o365AccountLinkedEmail = "";
        message.timeZone = "";
        message.mfaType = 0;
        message.recoveryEmail = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAccount): UpdateAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string display_name */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string phone_number */ 3:
                    message.phoneNumber = reader.string();
                    break;
                case /* string photo_url */ 4:
                    message.photoUrl = reader.string();
                    break;
                case /* string new_password */ 5:
                    message.newPassword = reader.string();
                    break;
                case /* string first_name */ 6:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 7:
                    message.lastName = reader.string();
                    break;
                case /* bool google_account_connected */ 8:
                    message.googleAccountConnected = reader.bool();
                    break;
                case /* bool o365_account_connected */ 9:
                    message.o365AccountConnected = reader.bool();
                    break;
                case /* string google_account_linked_email */ 10:
                    message.googleAccountLinkedEmail = reader.string();
                    break;
                case /* string o365_account_linked_email */ 11:
                    message.o365AccountLinkedEmail = reader.string();
                    break;
                case /* string time_zone */ 12:
                    message.timeZone = reader.string();
                    break;
                case /* MFAType mfa_type */ 13:
                    message.mfaType = reader.int32();
                    break;
                case /* string recovery_email */ 14:
                    message.recoveryEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string display_name = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string phone_number = 3; */
        if (message.phoneNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phoneNumber);
        /* string photo_url = 4; */
        if (message.photoUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.photoUrl);
        /* string new_password = 5; */
        if (message.newPassword !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.newPassword);
        /* string first_name = 6; */
        if (message.firstName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 7; */
        if (message.lastName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.lastName);
        /* bool google_account_connected = 8; */
        if (message.googleAccountConnected !== false)
            writer.tag(8, WireType.Varint).bool(message.googleAccountConnected);
        /* bool o365_account_connected = 9; */
        if (message.o365AccountConnected !== false)
            writer.tag(9, WireType.Varint).bool(message.o365AccountConnected);
        /* string google_account_linked_email = 10; */
        if (message.googleAccountLinkedEmail !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.googleAccountLinkedEmail);
        /* string o365_account_linked_email = 11; */
        if (message.o365AccountLinkedEmail !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.o365AccountLinkedEmail);
        /* string time_zone = 12; */
        if (message.timeZone !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.timeZone);
        /* MFAType mfa_type = 13; */
        if (message.mfaType !== 0)
            writer.tag(13, WireType.Varint).int32(message.mfaType);
        /* string recovery_email = 14; */
        if (message.recoveryEmail !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.recoveryEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateAccount
 */
export const UpdateAccount = new UpdateAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetPasswordRequest$Type extends MessageType<ResetPasswordRequest> {
    constructor() {
        super("ResetPasswordRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetPasswordRequest>): ResetPasswordRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<ResetPasswordRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetPasswordRequest): ResetPasswordRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetPasswordRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ResetPasswordRequest
 */
export const ResetPasswordRequest = new ResetPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FavoriteWorkplace$Type extends MessageType<FavoriteWorkplace> {
    constructor() {
        super("FavoriteWorkplace", [
            { no: 1, name: "type", kind: "enum", T: () => ["FavoriteWorkplaceEnum", FavoriteWorkplaceEnum] },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FavoriteWorkplace>): FavoriteWorkplace {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<FavoriteWorkplace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FavoriteWorkplace): FavoriteWorkplace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* FavoriteWorkplaceEnum type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FavoriteWorkplace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* FavoriteWorkplaceEnum type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FavoriteWorkplace
 */
export const FavoriteWorkplace = new FavoriteWorkplace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Account$Type extends MessageType<Account> {
    constructor() {
        super("Account", [
            { no: 1, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "photo_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "role", kind: "enum", T: () => ["Role", Role] },
            { no: 10, name: "schedule_status", kind: "enum", T: () => ["WorkScheduleStatus", WorkScheduleStatus] },
            { no: 11, name: "allow_to_find", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "google_account_connected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "o365_account_connected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "login_method", kind: "enum", T: () => ["LoginMethod", LoginMethod, "LOGIN_METHOD_"] },
            { no: 15, name: "google_account_linked_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "o365_account_linked_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "mfa_type", kind: "enum", T: () => ["MFAType", MFAType] },
            { no: 19, name: "recovery_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "permissions", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["RolePermissions", RolePermissions] },
            { no: 21, name: "favoriteWorkplaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FavoriteWorkplace }
        ], { "google.api.resource": { type: "auth/Account", pattern: ["customers/{customer}/accounts/{account}"] } });
    }
    create(value?: PartialMessage<Account>): Account {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.displayName = "";
        message.email = "";
        message.phoneNumber = "";
        message.photoUrl = "";
        message.customerId = "";
        message.firstName = "";
        message.lastName = "";
        message.id = "";
        message.role = 0;
        message.scheduleStatus = 0;
        message.allowToFind = false;
        message.googleAccountConnected = false;
        message.o365AccountConnected = false;
        message.loginMethod = 0;
        message.googleAccountLinkedEmail = "";
        message.o365AccountLinkedEmail = "";
        message.timeZone = "";
        message.mfaType = 0;
        message.recoveryEmail = "";
        message.permissions = [];
        message.favoriteWorkplaces = [];
        if (value !== undefined)
            reflectionMergePartial<Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Account): Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string display_name */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string phone_number */ 3:
                    message.phoneNumber = reader.string();
                    break;
                case /* string photo_url */ 4:
                    message.photoUrl = reader.string();
                    break;
                case /* string customer_id */ 5:
                    message.customerId = reader.string();
                    break;
                case /* string first_name */ 6:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 7:
                    message.lastName = reader.string();
                    break;
                case /* string id */ 8:
                    message.id = reader.string();
                    break;
                case /* Role role */ 9:
                    message.role = reader.int32();
                    break;
                case /* WorkScheduleStatus schedule_status */ 10:
                    message.scheduleStatus = reader.int32();
                    break;
                case /* bool allow_to_find */ 11:
                    message.allowToFind = reader.bool();
                    break;
                case /* bool google_account_connected */ 12:
                    message.googleAccountConnected = reader.bool();
                    break;
                case /* bool o365_account_connected */ 13:
                    message.o365AccountConnected = reader.bool();
                    break;
                case /* LoginMethod login_method */ 14:
                    message.loginMethod = reader.int32();
                    break;
                case /* string google_account_linked_email */ 15:
                    message.googleAccountLinkedEmail = reader.string();
                    break;
                case /* string o365_account_linked_email */ 16:
                    message.o365AccountLinkedEmail = reader.string();
                    break;
                case /* string time_zone */ 17:
                    message.timeZone = reader.string();
                    break;
                case /* MFAType mfa_type */ 18:
                    message.mfaType = reader.int32();
                    break;
                case /* string recovery_email */ 19:
                    message.recoveryEmail = reader.string();
                    break;
                case /* repeated RolePermissions permissions */ 20:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.permissions.push(reader.int32());
                    else
                        message.permissions.push(reader.int32());
                    break;
                case /* repeated FavoriteWorkplace favoriteWorkplaces */ 21:
                    message.favoriteWorkplaces.push(FavoriteWorkplace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string display_name = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string phone_number = 3; */
        if (message.phoneNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phoneNumber);
        /* string photo_url = 4; */
        if (message.photoUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.photoUrl);
        /* string customer_id = 5; */
        if (message.customerId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerId);
        /* string first_name = 6; */
        if (message.firstName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 7; */
        if (message.lastName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.lastName);
        /* string id = 8; */
        if (message.id !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.id);
        /* Role role = 9; */
        if (message.role !== 0)
            writer.tag(9, WireType.Varint).int32(message.role);
        /* WorkScheduleStatus schedule_status = 10; */
        if (message.scheduleStatus !== 0)
            writer.tag(10, WireType.Varint).int32(message.scheduleStatus);
        /* bool allow_to_find = 11; */
        if (message.allowToFind !== false)
            writer.tag(11, WireType.Varint).bool(message.allowToFind);
        /* bool google_account_connected = 12; */
        if (message.googleAccountConnected !== false)
            writer.tag(12, WireType.Varint).bool(message.googleAccountConnected);
        /* bool o365_account_connected = 13; */
        if (message.o365AccountConnected !== false)
            writer.tag(13, WireType.Varint).bool(message.o365AccountConnected);
        /* LoginMethod login_method = 14; */
        if (message.loginMethod !== 0)
            writer.tag(14, WireType.Varint).int32(message.loginMethod);
        /* string google_account_linked_email = 15; */
        if (message.googleAccountLinkedEmail !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.googleAccountLinkedEmail);
        /* string o365_account_linked_email = 16; */
        if (message.o365AccountLinkedEmail !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.o365AccountLinkedEmail);
        /* string time_zone = 17; */
        if (message.timeZone !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.timeZone);
        /* MFAType mfa_type = 18; */
        if (message.mfaType !== 0)
            writer.tag(18, WireType.Varint).int32(message.mfaType);
        /* string recovery_email = 19; */
        if (message.recoveryEmail !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.recoveryEmail);
        /* repeated RolePermissions permissions = 20; */
        if (message.permissions.length) {
            writer.tag(20, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.permissions.length; i++)
                writer.int32(message.permissions[i]);
            writer.join();
        }
        /* repeated FavoriteWorkplace favoriteWorkplaces = 21; */
        for (let i = 0; i < message.favoriteWorkplaces.length; i++)
            FavoriteWorkplace.internalBinaryWrite(message.favoriteWorkplaces[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Account
 */
export const Account = new Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetDeviceIDRequest$Type extends MessageType<SetDeviceIDRequest> {
    constructor() {
        super("SetDeviceIDRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deviceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetDeviceIDRequest>): SetDeviceIDRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.customerID = "";
        message.deviceID = "";
        if (value !== undefined)
            reflectionMergePartial<SetDeviceIDRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetDeviceIDRequest): SetDeviceIDRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string deviceID */ 3:
                    message.deviceID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetDeviceIDRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string deviceID = 3; */
        if (message.deviceID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SetDeviceIDRequest
 */
export const SetDeviceIDRequest = new SetDeviceIDRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PushNotificationsSubscribeRequest$Type extends MessageType<PushNotificationsSubscribeRequest> {
    constructor() {
        super("PushNotificationsSubscribeRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PushNotificationsSubscribeRequest>): PushNotificationsSubscribeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<PushNotificationsSubscribeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PushNotificationsSubscribeRequest): PushNotificationsSubscribeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PushNotificationsSubscribeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PushNotificationsSubscribeRequest
 */
export const PushNotificationsSubscribeRequest = new PushNotificationsSubscribeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PushNotificationsSubscribeResponse$Type extends MessageType<PushNotificationsSubscribeResponse> {
    constructor() {
        super("PushNotificationsSubscribeResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PushNotificationsSubscribeResponse>): PushNotificationsSubscribeResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<PushNotificationsSubscribeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PushNotificationsSubscribeResponse): PushNotificationsSubscribeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PushNotificationsSubscribeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PushNotificationsSubscribeResponse
 */
export const PushNotificationsSubscribeResponse = new PushNotificationsSubscribeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PushNotificationsUnsubscribeRequest$Type extends MessageType<PushNotificationsUnsubscribeRequest> {
    constructor() {
        super("PushNotificationsUnsubscribeRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PushNotificationsUnsubscribeRequest>): PushNotificationsUnsubscribeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<PushNotificationsUnsubscribeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PushNotificationsUnsubscribeRequest): PushNotificationsUnsubscribeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PushNotificationsUnsubscribeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PushNotificationsUnsubscribeRequest
 */
export const PushNotificationsUnsubscribeRequest = new PushNotificationsUnsubscribeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkingSchedule$Type extends MessageType<WorkingSchedule> {
    constructor() {
        super("WorkingSchedule", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "workingStatus", kind: "enum", T: () => ["WorkScheduleStatus", WorkScheduleStatus] },
            { no: 3, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WorkingSchedule>): WorkingSchedule {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.workingStatus = 0;
        message.date = "";
        message.startTime = "";
        message.endTime = "";
        if (value !== undefined)
            reflectionMergePartial<WorkingSchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorkingSchedule): WorkingSchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* WorkScheduleStatus workingStatus */ 2:
                    message.workingStatus = reader.int32();
                    break;
                case /* string date */ 3:
                    message.date = reader.string();
                    break;
                case /* string startTime */ 4:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 5:
                    message.endTime = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorkingSchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* WorkScheduleStatus workingStatus = 2; */
        if (message.workingStatus !== 0)
            writer.tag(2, WireType.Varint).int32(message.workingStatus);
        /* string date = 3; */
        if (message.date !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.date);
        /* string startTime = 4; */
        if (message.startTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 5; */
        if (message.endTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.endTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WorkingSchedule
 */
export const WorkingSchedule = new WorkingSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetWorkingScheduleRequest$Type extends MessageType<SetWorkingScheduleRequest> {
    constructor() {
        super("SetWorkingScheduleRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "schedule", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkingSchedule }
        ]);
    }
    create(value?: PartialMessage<SetWorkingScheduleRequest>): SetWorkingScheduleRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.customerID = "";
        message.schedule = [];
        if (value !== undefined)
            reflectionMergePartial<SetWorkingScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetWorkingScheduleRequest): SetWorkingScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* repeated WorkingSchedule schedule */ 3:
                    message.schedule.push(WorkingSchedule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetWorkingScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* repeated WorkingSchedule schedule = 3; */
        for (let i = 0; i < message.schedule.length; i++)
            WorkingSchedule.internalBinaryWrite(message.schedule[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SetWorkingScheduleRequest
 */
export const SetWorkingScheduleRequest = new SetWorkingScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWorkingScheduleRequest$Type extends MessageType<GetWorkingScheduleRequest> {
    constructor() {
        super("GetWorkingScheduleRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetWorkingScheduleRequest>): GetWorkingScheduleRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.customerID = "";
        message.startDate = "";
        message.endDate = "";
        if (value !== undefined)
            reflectionMergePartial<GetWorkingScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWorkingScheduleRequest): GetWorkingScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 3:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 4:
                    message.endDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWorkingScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 3; */
        if (message.startDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 4; */
        if (message.endDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.endDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetWorkingScheduleRequest
 */
export const GetWorkingScheduleRequest = new GetWorkingScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteWorkingScheduleRequest$Type extends MessageType<DeleteWorkingScheduleRequest> {
    constructor() {
        super("DeleteWorkingScheduleRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteWorkingScheduleRequest>): DeleteWorkingScheduleRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteWorkingScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteWorkingScheduleRequest): DeleteWorkingScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteWorkingScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteWorkingScheduleRequest
 */
export const DeleteWorkingScheduleRequest = new DeleteWorkingScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkingScheduleResponse$Type extends MessageType<WorkingScheduleResponse> {
    constructor() {
        super("WorkingScheduleResponse", [
            { no: 1, name: "schedule", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WorkingSchedule }
        ]);
    }
    create(value?: PartialMessage<WorkingScheduleResponse>): WorkingScheduleResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.schedule = [];
        if (value !== undefined)
            reflectionMergePartial<WorkingScheduleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorkingScheduleResponse): WorkingScheduleResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated WorkingSchedule schedule */ 1:
                    message.schedule.push(WorkingSchedule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorkingScheduleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated WorkingSchedule schedule = 1; */
        for (let i = 0; i < message.schedule.length; i++)
            WorkingSchedule.internalBinaryWrite(message.schedule[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WorkingScheduleResponse
 */
export const WorkingScheduleResponse = new WorkingScheduleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyMobileAPPAccountRequest$Type extends MessageType<VerifyMobileAPPAccountRequest> {
    constructor() {
        super("VerifyMobileAPPAccountRequest", [
            { no: 1, name: "accountEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyMobileAPPAccountRequest>): VerifyMobileAPPAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountEmail = "";
        message.deviceID = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyMobileAPPAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyMobileAPPAccountRequest): VerifyMobileAPPAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountEmail */ 1:
                    message.accountEmail = reader.string();
                    break;
                case /* string deviceID */ 2:
                    message.deviceID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyMobileAPPAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountEmail = 1; */
        if (message.accountEmail !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountEmail);
        /* string deviceID = 2; */
        if (message.deviceID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyMobileAPPAccountRequest
 */
export const VerifyMobileAPPAccountRequest = new VerifyMobileAPPAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyMobileAPPAccountResponse$Type extends MessageType<VerifyMobileAPPAccountResponse> {
    constructor() {
        super("VerifyMobileAPPAccountResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyMobileAPPAccountResponse>): VerifyMobileAPPAccountResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyMobileAPPAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyMobileAPPAccountResponse): VerifyMobileAPPAccountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyMobileAPPAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyMobileAPPAccountResponse
 */
export const VerifyMobileAPPAccountResponse = new VerifyMobileAPPAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyMobileAPPDeviceRequest$Type extends MessageType<VerifyMobileAPPDeviceRequest> {
    constructor() {
        super("VerifyMobileAPPDeviceRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyMobileAPPDeviceRequest>): VerifyMobileAPPDeviceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.deviceID = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyMobileAPPDeviceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyMobileAPPDeviceRequest): VerifyMobileAPPDeviceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string deviceID */ 2:
                    message.deviceID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyMobileAPPDeviceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string deviceID = 2; */
        if (message.deviceID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyMobileAPPDeviceRequest
 */
export const VerifyMobileAPPDeviceRequest = new VerifyMobileAPPDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyMobileAPPDeviceResponse$Type extends MessageType<VerifyMobileAPPDeviceResponse> {
    constructor() {
        super("VerifyMobileAPPDeviceResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyMobileAPPDeviceResponse>): VerifyMobileAPPDeviceResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyMobileAPPDeviceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyMobileAPPDeviceResponse): VerifyMobileAPPDeviceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyMobileAPPDeviceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyMobileAPPDeviceResponse
 */
export const VerifyMobileAPPDeviceResponse = new VerifyMobileAPPDeviceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestEmailChangeRequest$Type extends MessageType<RequestEmailChangeRequest> {
    constructor() {
        super("RequestEmailChangeRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "newEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RequestEmailChangeRequest>): RequestEmailChangeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.accountID = "";
        message.newEmail = "";
        if (value !== undefined)
            reflectionMergePartial<RequestEmailChangeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestEmailChangeRequest): RequestEmailChangeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 2:
                    message.accountID = reader.string();
                    break;
                case /* string newEmail */ 3:
                    message.newEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RequestEmailChangeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 2; */
        if (message.accountID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountID);
        /* string newEmail = 3; */
        if (message.newEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.newEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequestEmailChangeRequest
 */
export const RequestEmailChangeRequest = new RequestEmailChangeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyEmailChangeRequest$Type extends MessageType<VerifyEmailChangeRequest> {
    constructor() {
        super("VerifyEmailChangeRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyEmailChangeRequest>): VerifyEmailChangeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyEmailChangeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyEmailChangeRequest): VerifyEmailChangeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string token */ 3:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyEmailChangeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string token = 3; */
        if (message.token !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyEmailChangeRequest
 */
export const VerifyEmailChangeRequest = new VerifyEmailChangeRequest$Type();
/**
 * @generated ServiceType for protobuf service AccountService
 */
export const AccountService = new ServiceType("AccountService", [
    { name: "UpdateAllowToFind", options: {}, I: UpdateAllowToFindRequest, O: Account },
    { name: "UpdateTimeZone", options: {}, I: UpdateTimeZoneRequest, O: Account },
    { name: "UploadProfilePicture", options: {}, I: PictureRequest, O: PictureResponse },
    { name: "DeleteProfilePicture", options: {}, I: DeletePictureRequest, O: Empty },
    { name: "CreateAccount", options: { "google.api.http": { post: "/v1/customers/{customerId}/accounts", body: "account" }, "google.api.method_signature": ["customer_id,account"] }, I: CreateAccountRequest, O: Account },
    { name: "GetAccount", options: { "google.api.http": { get: "/v1/customers/{customerId}/accounts/{accountId}" }, "google.api.method_signature": ["customer_id,account_id"] }, I: GetAccountRequest, O: Account },
    { name: "UpdateAccount", options: { "google.api.http": { patch: "/v1/customers/{customerId}/accounts/{accountId}", body: "*" }, "google.api.method_signature": ["account,update_mask"] }, I: UpdateAccountRequest, O: Account },
    { name: "ResetPassword", options: { "google.api.http": { post: "/v1/accounts:resetpw", body: "*" } }, I: ResetPasswordRequest, O: Empty },
    { name: "EnableAccount", options: {}, I: EnableAccountRequest, O: Empty },
    { name: "DisableAccount", options: {}, I: DisableAccountRequest, O: Empty },
    { name: "DeleteAccount", options: {}, I: DeleteAccountRequest, O: Empty },
    { name: "SendActivationLink", options: { "google.api.http": { post: "/v1/accounts:activation", body: "*" } }, I: SendActivationEmailRequest, O: SendActivationEmailResponse },
    { name: "VerifyEmail", options: {}, I: VerifyEmailRequest, O: Empty },
    { name: "ResetContactPassword", options: { "google.api.http": { post: "/v1/accounts:resetcontactpw", body: "*" } }, I: ContactPasswordResetRequest, O: Empty },
    { name: "VerifyMobileAPPAccount", options: { "google.api.http": { post: "/v1/accounts/apps/verify", body: "*" } }, I: VerifyMobileAPPAccountRequest, O: VerifyMobileAPPAccountResponse },
    { name: "VerifyMobileAPPDevice", options: { "google.api.http": { post: "/v1/accounts/apps/verify/device", body: "*" } }, I: VerifyMobileAPPDeviceRequest, O: VerifyMobileAPPDeviceResponse },
    { name: "PushNotificationsSubscribe", options: { "google.api.http": { post: "/v1/accounts/notification/auth", body: "*" } }, I: PushNotificationsSubscribeRequest, O: PushNotificationsSubscribeResponse },
    { name: "PushNotificationsUnsubscribe", options: { "google.api.http": { post: "/v1/accounts/notification/auth/deactivate", body: "*" } }, I: PushNotificationsUnsubscribeRequest, O: Empty },
    { name: "SetWorkingSchedule", options: { "google.api.http": { post: "/v1/accounts/schedule/{accountID}", body: "*" }, "google.api.method_signature": ["accountID"] }, I: SetWorkingScheduleRequest, O: WorkingScheduleResponse },
    { name: "GetWorkingSchedule", options: { "google.api.http": { get: "/v1/accounts/schedule/{accountID}" }, "google.api.method_signature": ["accountID"] }, I: GetWorkingScheduleRequest, O: WorkingScheduleResponse },
    { name: "DeleteWorkingSchedule", options: {}, I: DeleteWorkingScheduleRequest, O: Empty },
    { name: "SyncAccounts", options: { "google.api.http": { get: "/v1/accounts/sync" } }, I: Empty, O: Empty },
    { name: "RequestEmailChange", options: { "google.api.http": { patch: "/v1/accounts/email-change" } }, I: RequestEmailChangeRequest, O: Empty },
    { name: "VerifyEmailChange", options: { "google.api.http": { patch: "/v1/accounts/email-change-verify" } }, I: VerifyEmailChangeRequest, O: Empty },
    { name: "SetRecoveryEmail", options: { "google.api.http": { post: "/v1/accounts/recovery-email" } }, I: RecoveryEmailRequest, O: RecoveryEmailResponse },
    { name: "SendRecoveryEmail", options: { "google.api.http": { patch: "/v1/accounts/send-recovery-email" } }, I: SendRecoveryEmailRequest, O: SendRecoveryEmailResponse },
    { name: "DisableMFA", options: { "google.api.http": { patch: "/v1/accounts/disable-mfa" } }, I: DisableMFARequest, O: DisableMFAResponse },
    { name: "DisableManualMFA", options: { "google.api.http": { post: "/v1/accounts/disable-manual-mfa" } }, I: DisableManualMFARequest, O: DisableManualMFAResponse }
]);
