import React from "react";
import { useReservationHelpers } from "../../Reservations/helpers/reservationUpdateHelpers";
import { useApolloClient } from "@apollo/client";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { utcToZonedTime } from "date-fns-tz";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { capitalizeFirstLetter } from "../../../../lib/helpers/capitalizeFirstLetter";

import { CheckInRequiredStatus } from "../../../../api/grpc/booking/ggevent/ggevent";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
} from "../../../Workplaces/helpers/getWorkplaceFragment";
import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";

export const DeleteModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  refetchRoom,
  disableEventActions,
  isAuthedUsersEvent,
}: ExtendedProps) => {
  const client = useApolloClient();
  const { timeZone } = useTimeZoneContext();
  const { personalCalendar } = useCalendarContext();
  const { user } = useAuthContext();

  const { deleteDeskReservation, deleteRoomReservation } =
    useReservationHelpers();

  const workplaceData = client.readFragment({
    id: `${capitalizeFirstLetter(type)}:${workplaceId}`,
    fragmentName: getWorkplaceFragmentName(capitalizeFirstLetter(type)),
    fragment: getWorkplaceFragment(capitalizeFirstLetter(type)),
  });

  const definedUsername = !!personalCalendar?.length
    ? event?.organizer?.displayName || ""
    : userName;

  const handleDeleteReservation = async () => {
    if (type === "desk" && refetchDesk) {
      return await deleteDeskReservation({
        deskID: workplaceId,
        eventID: event.metaData.DESK_EVENT_ID,
        accountID: event?.organizer?.id || "",
        refetch: refetchDesk,
      });
    }

    if (refetchRoom) {
      const owner = personalCalendar.length > 0 && isAuthedUsersEvent;

      const calendarId = owner ? "" : workplaceData?.calendarId;

      const calendarproviderId = owner
        ? personalCalendar[0].iD
        : workplaceData?.calendarProviderId;

      return await deleteRoomReservation(
        calendarId,
        calendarproviderId,
        event.metaData,
        refetchRoom,
        event.checkInRequiredStatus === CheckInRequiredStatus.CHECK_IN_REQUIRED,
        user?.email || ""
      );
    }
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="Delete reservation?"
      icon="delete-reservation"
      workspaceType={type}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      // day={useFormatReservationDate(new Date(startTime))}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={
        type === "desk" && event.allDay === true
          ? "00:00"
          : dateToHoursAndMinutes(new Date(endTime), timeZone)
      }
      userName={definedUsername}
      locationPath={locationPath}
      type="delete"
      buttonClick={handleDeleteReservation}
      buttonTitle="Delete"
      handleModalClose={toggleModal}
      classNames="modal-footer-row"
      subject={event?.title}
      allDay={event.allDay}
      disableEventActions={disableEventActions}
    />
  );
};
