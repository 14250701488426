import React, { useEffect, useMemo, useState } from "react";
import { RpcInterceptor, UnaryCall } from "@protobuf-ts/runtime-rpc";
import { getApps, initializeApp } from "firebase/app";
import {
  reauthenticateWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  verifyPasswordResetCode,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  confirmPasswordReset,
  User,
  getAuth,
  signOut as signOutFirebase,
  TotpMultiFactorGenerator,
  multiFactor,
  TotpSecret,
  getMultiFactorResolver,
  MultiFactorError,
} from "firebase/auth";

import { useAccountRequests } from "../../../api/grpc/account/useAccountRequests";

import { transport } from "../../../api/grpc/grpcTransport";
import { CustomerzClient } from "../../../api/grpc/customer/customer.client";

import {
  AuthContext,
  OptionalUserFields,
  UserFields,
  UserAccess,
  SessionType,
  ProviderType,
} from "./AuthContext";
import { CreateCustomerRequest } from "../../../api/grpc/customer/customer";
import { Roles } from "../../routes/routes";

export interface ResponseMessage {
  type: string | undefined;
  text: string | undefined;
}

interface Props {
  children: React.ReactNode;
}

export type DeviceType = "rdx" | "wfx";

export const AuthContextProvider = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [ssoLoading, setSsoLoading] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [user, setUser] = useState<UserFields | undefined>(undefined);
  const [userAccess, setUserAccess] = useState<UserAccess>("not-authenticated");
  const [userTotp, setUserTotp] = useState<
    { totpSecret: TotpSecret; qRCodeURL: string } | undefined
  >(undefined);
  const { getAccount, resetPassword } = useAccountRequests();
  const customerClient = useMemo(() => new CustomerzClient(transport), []);

  useEffect(() => {
    initFirebase();

    initializeAuthObserver();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initFirebase = () => {
    if (getApps().length) {
      return;
    }

    const config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    };

    initializeApp(config);
  };

  const updateUser = (fields?: OptionalUserFields) => {
    if (!user || !fields) {
      return;
    }

    setUser((prev) => {
      return { ...prev, ...fields } as UserFields;
    });
  };

  const initializeAuthObserver = () => {
    getAuth().onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser === null) {
        setLoading(false);
        setUser(undefined);
        setUserAccess("not-authenticated");
        return;
      }

      return await updateUserOnStateChange(firebaseUser);
    });
  };

  const updateUserOnStateChange = async (firebaseUser: User) => {
    try {
      const { claims, token } = await firebaseUser.getIdTokenResult(true);

      setSsoLoading(true);
      const { response } = await getAccount(
        claims.customerId,
        claims.user_id,
        token
      );

      // if (!claims.email_verified || response.role !== Role.ADMIN) {
      if (!claims.email_verified && !claims.consent_given) {
        setUserAccess("no-permission");
        setSsoLoading(false);

        return;
      }

      setUser({
        token,
        claims,
        email: response.email,
        name: response.displayName,
        picture: response.photoUrl,
        orgid: response.customerId,
        lastName: response.lastName,
        firstName: response.firstName,
        customerid: response.customerId,
        displayName: response.displayName,
        permissions: response.permissions,
        favoriteWorkplaces: response.favoriteWorkplaces,
        allowToFind: response.allowToFind,
        googleAccountConnected: response.googleAccountConnected,
        o365AccountConnected: response.o365AccountConnected,
        googleAccountLinkedEmail: response.googleAccountLinkedEmail,
        o365AccountLinkedEmail: response.o365AccountLinkedEmail,
        timeZone: response.timeZone,
        mfaType: response.mfaType,
        recoveryEmail: response.recoveryEmail,
      });

      setUserAccess("authenticated");
    } catch (error: any) {
      console.error(error.message);
      setUser(undefined);
      setUserAccess("not-authenticated");
    } finally {
      setLoading(false);
      setSsoLoading(false);
    }
  };

  const updateUserAvatarAfterPersonalLinked = async () => {
    try {
      const { response } = await getAccount(
        user?.customerid || "",
        user?.claims.user_id || "",
        user?.token || ""
      );

      updateUser({
        picture: response.photoUrl,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const verifyPassword = async (password: string) => {
    if (!user) {
      return;
    }

    getAuth().tenantId = user.customerid;

    const { currentUser } = getAuth();

    if (currentUser === null) {
      return;
    }

    const credential = EmailAuthProvider.credential(
      user?.email?.toLowerCase() || "",
      password
    );

    return await reauthenticateWithCredential(currentUser, credential);
  };

  const updatePasswordUser = async (newPassword: string) => {
    const { currentUser } = getAuth();

    if (currentUser === null) {
      return;
    }

    return await updatePassword(currentUser, newPassword);
  };

  const login = async (username: string, password: string, id: string) => {
    let localTokenStored = localStorage.getItem("token");

    if (localTokenStored) {
      localStorage.removeItem("token");
    }

    const auth = getAuth();

    auth.tenantId = id;

    return await signInWithEmailAndPassword(
      auth,
      username.toLowerCase(),
      password
    );
  };

  const getTenant = async (username: string) => {
    return await customerClient.findCustomer({
      email: username.toLowerCase(),
    });
  };

  const getTokenHeader = async () => {
    let localToken = localStorage.getItem("token");

    if (localToken) {
      localStorage.removeItem("token");
      return localToken;
    }

    const { currentUser } = getAuth();

    if (!currentUser) {
      throw new Error("User not found, please refresh the page!");
    }

    return await currentUser.getIdToken();
  };

  const createCustomer = async (customer: CreateCustomerRequest) => {
    return await customerClient.createCustomer({
      customer: customer.customer,
      validateDomain: customer.validateDomain,
    });
  };

  const tokenInterceptor: RpcInterceptor = {
    interceptUnary(next, method, input, options): UnaryCall {
      // next() creates a call instance, sending data (including headers) to the server.
      // we want to wait until we have our token:

      const callPromise = new Promise<UnaryCall<any, any>>((resolve) => {
        getTokenHeader().then((token) => {
          if (!options.meta) {
            options.meta = {};
          }

          options.meta.jwt = token;
          resolve(next(method, input, options));
        });
      });

      // we have to return a valid call instance immediately.
      // we wrap the promised call.
      return new UnaryCall<any, any>(
        method,
        options.meta ?? {},
        input,
        callPromise.then((c) => c.headers),
        callPromise.then((c) => c.response),
        callPromise.then((c) => c.status),
        callPromise.then((c) => c.trailers)
      );
    },
  };

  const verifyPasswordResetCodeUser = async (
    code: string,
    tenantId: string
  ) => {
    const auth = getAuth();

    auth.tenantId = tenantId;

    return await verifyPasswordResetCode(auth, code);
  };

  const confirmPasswordResetUser = async (
    code: string,
    newPassword: string
  ) => {
    const auth = getAuth();

    return await confirmPasswordReset(auth, code, newPassword);
  };

  const signOut = async () => {
    const auth = getAuth();

    if (user === null) {
      return;
    }

    if (user?.o365AccountConnected) {
      localStorage.clear();
    }

    return await signOutFirebase(auth);
  };

  const chargebeeProperties = (type: SessionType) => {
    return {
      sectionType: window.Chargebee.getPortalSections()[type],
      params: {
        subscriptionId: user?.claims.subscriptionId,
      },
    };
  };

  const downloadApk = async (type: DeviceType) => {
    if (type === "rdx") {
      return await customerClient.getRDXLastRelease("");
    }
    return await customerClient.getRFXLastRelease("");
  };

  const signInWithProvider = async (token: string) => {
    const auth = getAuth();

    return await signInWithCustomToken(auth, token);
  };

  const signUpWithProvider = async (
    tenantId: string,
    providerType: ProviderType
  ) => {
    const auth = getAuth();

    auth.tenantId = tenantId;
    let provider;

    if (providerType === "google") {
      provider = new GoogleAuthProvider();
    } else {
      provider = new OAuthProvider("microsoft.com");
    }

    provider.setCustomParameters({ prompt: "select_account" });

    return await signInWithPopup(auth, provider);
  };

  const signOutFromProvider = async () => {
    const { signOut } = getAuth();

    return await signOut();
  };

  const verifyEnrollement = () => {
    if (!user) {
      return;
    }

    getAuth().tenantId = user.customerid;

    const { currentUser } = getAuth();

    if (currentUser === null) {
      return;
    }

    return;
  };

  const generateTotpSecret = async () => {
    const { currentUser } = getAuth();

    if (currentUser === null || !currentUser.email) {
      return;
    }

    const multiFactorSession = await multiFactor(currentUser).getSession();

    const totpSecret = await TotpMultiFactorGenerator.generateSecret(
      multiFactorSession
    );

    const qRCodeURL = totpSecret.generateQrCodeUrl(
      currentUser.email,
      process.env.REACT_APP_BRAND_NAME
    );

    return setUserTotp({
      totpSecret,
      qRCodeURL,
    });
  };

  const enrollUserForTotp = async (codeFromAuthApp: string) => {
    const { currentUser } = getAuth();

    if (!currentUser || !userTotp?.totpSecret) {
      return;
    }

    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForEnrollment(
        userTotp.totpSecret,
        codeFromAuthApp
      );

    await multiFactor(currentUser).enroll(multiFactorAssertion, "TOTP");

    return;
  };

  const unEnrollUserForTotp = async () => {
    const { currentUser } = getAuth();

    if (!currentUser) {
      return;
    }

    await multiFactor(currentUser).unenroll(
      multiFactor(currentUser).enrolledFactors[0].uid
    );

    return;
  };

  const loginWithTotp = async (
    error: MultiFactorError,
    codeFromApp: string
  ) => {
    const auth = getAuth();

    const resolver = getMultiFactorResolver(auth, error);

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      resolver.hints[0].uid,
      codeFromApp
    );

    return resolver.resolveSignIn(multiFactorAssertion);
  };
  // const linkGoogleAccount = async () => {
  //   const { currentUser } = getAuth();

  //   const provider = new GoogleAuthProvider();

  //   if (currentUser === null) {
  //     return;
  //   }

  //   provider.setCustomParameters({ prompt: "select_account" });

  //   return await linkWithPopup(currentUser, provider);
  // };

  // const unLinkGoogleAccount = async () => {
  //   const provider = new GoogleAuthProvider();
  //   const user = getAuth().currentUser;

  //   if (user === null) {
  //     return;
  //   }

  //   return await unlink(user, provider.providerId);
  // };

  // const linkMicrosoftAccount = async () => {
  //   const provider = new OAuthProvider("microsoft.com");
  //   const user = getAuth().currentUser;

  //   if (user === null) {
  //     return;
  //   }

  //   provider.setCustomParameters({ prompt: "select_account" });

  //   return await linkWithPopup(user, provider);
  // };

  // const unLinkMicrosoftAccount = async () => {
  //   const provider = new OAuthProvider("microsoft.com");
  //   const user = getAuth().currentUser;

  //   if (user === null) {
  //     return;
  //   }

  //   return await unlink(user, provider.providerId);
  // };

  const context: AuthContext = {
    user,
    loading,
    userAccess,
    login,
    signOut,
    ssoLoading,
    setSsoLoading,
    getTenant,
    updateUser,
    resetPassword,
    createCustomer,
    verifyPassword,
    updatePassword: updatePasswordUser,
    getTokenHeader,
    tokenInterceptor,
    recoveryEmail,
    setRecoveryEmail,
    isMemberRole: [Roles.member, Roles.contact].includes(user?.claims.role),
    confirmPasswordReset: confirmPasswordResetUser,
    verifyPasswordResetCode: verifyPasswordResetCodeUser,
    isAuthenticated: user !== undefined,
    chargebeeProperties,
    downloadApk,
    signInWithProvider,
    signUpWithProvider,
    signOutFromProvider,
    updateUserAvatarAfterPersonalLinked,
    generateTotpSecret,
    enrollUserForTotp,
    unEnrollUserForTotp,
    verifyEnrollement,
    loginWithTotp,
    userTotp,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
