import React, { Dispatch, SetStateAction } from "react";
import { Person } from "../../../../api/grpc/contacts/contacts";

export interface IBookingsMapContext {
  time: Date;
  handleTime: (time: Date) => void;
  day: string;
  handleDay: (day: string) => void;
  searchByDate: () => void;
  dateAndTime: string;
  setDateAndTime: Dispatch<SetStateAction<string>>;
  bookOnBehalfUser?: Person;
  setBookOnBehalfUser: Dispatch<SetStateAction<Person | undefined>>;
  searchedAttendees: Person[];
  handleSearchedAttendees: (attendee: Person[]) => void;
  allDay: boolean;
  setAllDay: React.Dispatch<React.SetStateAction<boolean>>;
  notifyAssignee: boolean;
  setNotifyAssignee: React.Dispatch<React.SetStateAction<boolean>>;
}

const values: IBookingsMapContext = {
  time: new Date(),
  handleTime: () => {},
  day: "",
  handleDay: () => {},
  searchByDate: () => {},
  dateAndTime: "",
  setDateAndTime: () => {},
  searchedAttendees: [],
  bookOnBehalfUser: undefined,
  setBookOnBehalfUser: () => {},
  handleSearchedAttendees: () => {},
  allDay: false,
  setAllDay: () => {},
  notifyAssignee: false,
  setNotifyAssignee: () => {},
};

export const BookingsMapContext = React.createContext(values);

export const useBookingsMapContext = () => {
  const context = React.useContext(BookingsMapContext);

  if (!context) {
    throw new Error(
      "useBookingsMapContext must be used inside of BookingsMapContext"
    );
  }

  return context;
};
