import {
  CheckInRequiredStatus,
  EventCheckInStatus,
} from "../../../../api/grpc/booking/ggevent/ggevent";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";

const editOption = {
  icon: "show-extend-icon",
  label: "Show/Extend",
  type: "modifyReservation",
};

const openMapOption = {
  icon: "open-map-icon",
  label: "Open on map",
  type: "openMap",
};

const deleteOption = {
  icon: "delete-default",
  label: "Delete reservation",
  type: "deleteReservation",
};

export const getReservationTypes = ({
  typeOfCheckin,
  workplaceType,
  startTime,
  isCheckInRequired,
  allowDeleteOption,
  allDay,
  status,
  isAttendee,
  allowToModifyEvent,
}: {
  typeOfCheckin: EventCheckInStatus | null;
  workplaceType: BookingTypes;
  startTime: string;
  isCheckInRequired: CheckInRequiredStatus;
  allowDeleteOption: boolean;
  allDay: boolean;
  status?: string;
  isAttendee?: boolean;
  allowToModifyEvent?: boolean;
}) => {
  const definedEditOption =
    allowToModifyEvent && !isAttendee
      ? editOption
      : {
          ...editOption,
          label: "Show",
        };

  const defaultOptions = [definedEditOption, openMapOption];

  if (!allowToModifyEvent || (new Date() > new Date(startTime) && allDay)) {
    return defaultOptions;
  }

  if (workplaceType === "desk") {
    if (typeOfCheckin === EventCheckInStatus.CHECK_IN_OPEN) {
      return [
        {
          icon: "checkout-small",
          label: "Check in (confirm)",
          type: "checkInDesk",
        },
        ...defaultOptions,
        deleteOption,
      ];
    }

    function getIsCheckOutActive(): boolean {
      const checkInRequired =
        isCheckInRequired === CheckInRequiredStatus.CHECK_IN_REQUIRED;
      const isCheckInActivated =
        typeOfCheckin === EventCheckInStatus.CHECK_IN_ACTIVATED;
      const isEventStarted = new Date() > new Date(startTime);

      if (checkInRequired) {
        return isCheckInActivated && isEventStarted;
      }

      return isEventStarted;
    }

    const isCheckOutActive = getIsCheckOutActive();

    if (isCheckOutActive) {
      return [
        {
          icon: "checkout-small",
          label: "Check out (end now)",
          type: "checkOut",
        },
        ...defaultOptions,
      ];
    } else {
      return [...defaultOptions, deleteOption];
    }
  }

  //rooms
  if (isAttendee) {
    return defaultOptions;
  }

  if (status && ["Declined", "Maybe"].includes(status)) {
    return [deleteOption];
  }

  if (new Date() > new Date(startTime) && !allDay) {
    return [
      {
        icon: "checkout-small",
        label: "Check out (end now)",
        type: "checkOut",
      },
      ...defaultOptions,
    ];
  } else {
    return [...defaultOptions, deleteOption];
  }
};
